import { ErrorMessage } from "@hookform/error-message";
import { LogoRevo } from "assets/index";
import { useEffect } from "react";
import { Control, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { api, useLoginMutation } from "shared/api";
import ThemeFormProvider from "shared/components/form/provider";
import Button from "shared/components/ui/Button";
import { useAppDispatch } from "shared/store";
import { errorToast, successToast } from "shared/utils";
import yup, { yupResolver } from "shared/utils/yup";

const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const LoginScreen = () => {
    const [login, loginRes] = useLoginMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const revoToken = localStorage.getItem("revo_token");

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        login({
            username: data.username,
            password: data.password,
        }).then((res: any) => {
            if (res.data?.error) {
                return errorToast(res.data.error);
            }
            // reset api
            dispatch(api.util.resetApiState());
            localStorage.setItem("revo_token", res.data.token);
            successToast("Login successful");
            navigate("/dashboard");
        });
    };

    useEffect(() => {
        if (revoToken) {
            navigate("/dashboard");
        }
    }, [revoToken]);

    return (
        <section className="bg-gray-50">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
                    <LogoRevo />
                </a>
                <div className="w-full bg-white rounded-md shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                            Sign in to your account
                        </h1>
                        <ThemeFormProvider onSubmit={onSubmit} form={form} className="space-y-4 md:space-y-6">
                            <InputField
                                label="Username"
                                name="username"
                                control={form.control}
                                type="text"
                                placeholder="Enter your username"
                            />
                            <InputField
                                label="Password"
                                name="password"
                                control={form.control}
                                type="password"
                                placeholder="••••••••"
                            />
                            <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            aria-describedby="remember"
                                            type="checkbox"
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                                <a
                                    href="#"
                                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                                >
                                    Forgot password?
                                </a>
                            </div>
                            <Button
                                loading={loginRes.isLoading}
                                loadingText="Signing in..."
                                type="submit"
                                fullWidth
                                size="large"
                                color="primary"
                            >
                                Sign In
                            </Button>
                        </ThemeFormProvider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginScreen;

const InputField = (props: {
    label: string;
    name: keyof FormValues;
    control: Control<FormValues>;
    type: "text" | "password";
    placeholder: string;
}) => {
    const { field, formState } = useController({
        name: props.name,
        control: props.control,
    });

    return (
        <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">{props.label}</label>
            <input
                type={props.type}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder={props.placeholder}
            />
            <ErrorMessage
                errors={formState.errors}
                name={props.name}
                render={(res) => {
                    return <p className="text-sm text-red-500 mt-1">{res.message}</p>;
                }}
            />
        </div>
    );
};
