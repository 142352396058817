import { useEffect, useState } from "react";
import { useProductsQuery } from "shared/api";

const usePriceListContent = () => {
    const [products, setProducts] = useState<any[]>([]);
    const [filters, setFilters] = useState<
        {
            type: string;
            value: string;
        }[]
    >([]);
    const [page, setPage] = useState(1);

    const [sort, setSort] = useState<"asc" | "desc" | "price-asc" | "price-desc">("asc");
    const [search, setSearch] = useState("");

    const { data, isLoading, isFetching } = useProductsQuery(undefined);

    const [selectedColumns, setSelectedColumns] = useState<any[]>(["Wattage/Lumens", "Voltage"]);

    useEffect(() => {
        if (data?.length) setProducts(data);
    }, [data]);

    // get unqiue column fields from products
    const columns = products.map((product) => product.fields).flat();
    const uniqueColumns = Array.from(new Set(columns.map((a) => a.name))).map((name) => {
        return columns.find((a) => a.name === name);
    });

    const searchedProducts = products.filter((product) => {
        const searchValue = search.toLowerCase();
        return product.name.toLowerCase().includes(searchValue);
    });

    // filter products based on filters
    const filteredProducts = searchedProducts.filter((product) => {
        return filters.every((filter) => {
            if (filter.type === "Type") {
                return product.parent_categories.map((category: any) => category.id).includes(filter.value);
            }

            return product.fields.some((field) => {
                return field.name === filter.type && field.options.some((option) => option.name === filter.value);
            });
        });
    });

    const columnNames = uniqueColumns.map((column) => column.name);

    const handleScroll = (e: any) => {};

    return {
        products: filteredProducts,
        selectedColumns,
        setSelectedColumns,
        columnNames,
        sort,
        data,
        setSort,
        search,
        setPage,
        setSearch,
        filters,
        handleScroll,
        setFilters,
        isLoading,
        isFetching,
    };
};

export default usePriceListContent;
