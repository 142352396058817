import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from "@mui/material";
import { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useCustomersQuery } from "shared/api";

const CustomerSelect = (props: { onChange?: (value: any) => void; variant?: "outlined" | "standard" }) => {
    const [open, setOpen] = useState(false);
    const form = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name: "customer_id",
        control: form?.control,
    });

    const { data: cData, isLoading: loading } = useCustomersQuery(undefined, {
        skip: open === false,
    });

    const error = form.formState.errors.customer_id;

    return (
        <FormControl
            sx={{
                ".MuiButtonBase-root": {
                    backgroundColor: "transparent !important",
                },
            }}
            fullWidth
            error={error ? true : false}
        >
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth
                value={value ?? null}
                onChange={(e, value) => {
                    if (props.onChange) props.onChange(value);
                    onChange(value);
                }}
                id="customer-select"
                options={cData ?? []}
                isOptionEqualToValue={(option: any, value) => option.customer_name === value.customer_name}
                getOptionLabel={(option) => option.customer_name}
                loading={loading}
                loadingText="Loading..."
                filterOptions={(options, state) => {
                    let newOptions: any[] = [];
                    options.forEach((element) => {
                        if (element.customer_name.toLowerCase().includes(state.inputValue.toLowerCase()))
                            newOptions.push(element);
                    });
                    return newOptions;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.variant ? (props.variant === "outlined" ? "Customer" : "") : "Customer"}
                        error={error ? true : false}
                        placeholder="Search customer"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {error && <FormHelperText error>{(error as any).message}</FormHelperText>}
        </FormControl>
    );
};

export default CustomerSelect;
