import { createContext, useContext } from "react";

export interface AddProductContext {
    selectedCategory: {
        parent: any;
        sub_category: any;
    };
    setSelectedCategory: React.Dispatch<
        React.SetStateAction<{
            parent: any;
            sub_category: any;
        }>
    >;
    selectedProduct: any;
    setSelectedProduct: React.Dispatch<React.SetStateAction<any>>;
    productFields: any;
    changedOptions: any;
    setChangedOptions: (optionId: number, field: any) => void;
    selectedProductPrice: number;
    selectedProductLineTotal: number;
    selectedProductHotPrice: number;
    selectedProductNormalPrice: number;
    selectedProductBasePrice: number;
}

const Context = createContext<AddProductContext>({
    selectedCategory: {
        parent: null,
        sub_category: null,
    },
    setSelectedCategory: () => {},
    selectedProduct: null,
    setSelectedProduct: () => {},
    productFields: null,
    changedOptions: [],
    setChangedOptions: (id: number, field: any) => {},
    selectedProductPrice: 0,
    selectedProductLineTotal: 0,
    selectedProductHotPrice: 0,
    selectedProductNormalPrice: 0,
    selectedProductBasePrice: 0,
});

export const AddProductContext = (props: { children: React.ReactNode; value: AddProductContext }) => {
    return <Context.Provider value={props.value}>{props.children}</Context.Provider>;
};

export const useAddProductContext = () => {
    return useContext(Context);
};
