import { CircularProgress } from "@mui/material";
import { PlusIcon } from "assets/index";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetProjectByIdQuery, useProjectsQuery, useQuoteRequestByIDQuery } from "shared/api";
import EditProjectModal from "shared/components/EditProjectModal";
import Button from "shared/components/ui/Button";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import useAssetType from "shared/hooks/useAssetType";
import { useAppDispatch, useAppSelector } from "shared/store";
import { AssetTypes } from "shared/utils";
import { DefaultQuotation } from "shared/utils/dummy-data";
import Filter from "./components/Filter";
import QuoteNav from "./components/QuoteNav";
import QuoteTab from "./components/QuoteTab";
import { setProject } from "shared/store/slices";

const ProjectDetailScreen = () => {
    const { id, type } = useParams<{ id: string; type: AssetTypes }>();
    const dispatch = useAppDispatch();
    const selectedProject = useAppSelector((state) => state.project.selected);
    const { isProject, isQuotation } = useAssetType();
    const projectDetail = useProjectDetailContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoading: projectLoading } = useProjectsQuery(undefined, {
        skip: !isProject,
    });
    const searchedValue = searchParams.get("search") || "";
    const { data, isLoading, isFetching } = useGetProjectByIdQuery(id ?? "", {
        skip: !id || !isProject,
    });

    const {
        data: quoteData,
        isLoading: quoteLoading,
        isFetching: quoteIsFetching,
    } = useQuoteRequestByIDQuery(id ?? "", {
        skip: !id || !isQuotation,
    });

    const addQuote = () => {
        const newQuotation = DefaultQuotation();
        if (isProject) {
            newQuotation.CardCode = selectedProject?.customer?.customer_code;
            newQuotation.CardName = selectedProject?.customer?.customer_name;
            newQuotation.customer = selectedProject?.customer;
        }
        projectDetail.setQuotations([...projectDetail.quotations, newQuotation]);
        projectDetail.setSelectedQuotation(newQuotation);
    };

    useEffect(() => {
        return () => {
            dispatch(setProject(undefined));
        };
    }, []);

    useEffect(() => {
        if (data?.quotations.length) {
            projectDetail.setQuotations(data.quotations);
            projectDetail.setSelectedQuotation(data.quotations[0]);
        } else {
            projectDetail.setQuotations([]);
            projectDetail.setSelectedQuotation(undefined);
        }
    }, [data, quoteData, type, isLoading, quoteLoading]);

    useEffect(() => {
        if (quoteData) {
            projectDetail.setQuotations([quoteData]);
            projectDetail.setSelectedQuotation(quoteData);
        } else {
            projectDetail.setQuotations([]);
            projectDetail.setSelectedQuotation(undefined);
        }
    }, [quoteData]);

    const quotations = useMemo(() => {
        return projectDetail.quotations.filter((quote) => {
            return searchedValue
                ? quote?.Name.toLowerCase().includes(searchedValue.toLowerCase()) ||
                      quote?.customer?.customer_name.toLowerCase().includes(searchedValue.toLowerCase()) ||
                      quote?.customer?.customer_code.toLowerCase().includes(searchedValue.toLowerCase()) ||
                      quote?.detail?.filter(
                          (detail: any) =>
                              detail?.Description.toLowerCase().includes(searchedValue.toLowerCase()) ||
                              detail?.GenericModel.toLowerCase().includes(searchedValue.toLowerCase()),
                      ).length > 0
                : true;
        });
    }, [projectDetail.quotations, searchedValue]);

    useEffect(() => {
        if (!isProject) return;
        if (quotations.length > 0) {
            projectDetail.setSelectedQuotation(quotations[0]);
        } else {
            projectDetail.setSelectedQuotation(undefined);
        }
    }, [isProject, searchedValue]);

    return (
        <>
            {!projectLoading && isProject && <Filter onEdit={() => setModalOpen(true)} />}
            {projectLoading || isLoading || isFetching || quoteLoading || quoteIsFetching ? (
                <div className="h-36 flex items-center justify-center">
                    <CircularProgress size={30} color="primary" />
                </div>
            ) : projectDetail.quotations.length === 0 ? (
                <div className="p-9 flex items-center justify-center bg-[#FBFBFB] border border-[#D5D5D5] border-dashed rounded-md">
                    <Button
                        variant="text"
                        startIcon={<PlusIcon />}
                        className="!text-[#828282] !font-bold"
                        onClick={addQuote}
                    >
                        New Quotation
                    </Button>
                </div>
            ) : (
                <div className="relative">
                    {quotations.length === 0 ? (
                        <div className="p-9 flex items-center justify-center bg-[#FBFBFB] border border-[#D5D5D5] border-dashed rounded-md">
                            <p className="text-[#6B7280]">No Quotation Found</p>
                        </div>
                    ) : (
                        <>
                            <QuoteNav
                                quotations={quotations}
                                selected={projectDetail.selectedQuotation?.DocNum ?? 0}
                                onAddQuote={addQuote}
                            />
                            <QuoteTab />
                        </>
                    )}
                </div>
            )}
            <EditProjectModal onClose={() => setModalOpen(false)} open={modalOpen} />
        </>
    );
};

export default ProjectDetailScreen;
