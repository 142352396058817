import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { useProjectsQuery } from "shared/api";
import { errorToast } from "shared/utils";
import { DashboardFilters } from "..";
import FetchingLoader from "shared/components/ui/FetchingLoader";

const ProjectList = (props: {
    filters: DashboardFilters;
    setQuotaModal: React.Dispatch<React.SetStateAction<boolean>>;
    setProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { data, isLoading, isFetching, error } = useProjectsQuery({
        startDate: props.filters.dateRange.value?.[0].toISOString(),
        endDate: props.filters.dateRange.value?.[1].toISOString(),
        search: props.filters.search ?? "",
    });

    if (error) {
        errorToast((error as any).message);
        return <div>Error</div>;
    }

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between my-4">
                <h4 className="text-black text-[18px] font-bold">My Projects ({data?.length ?? 0})</h4>
            </div>
            <div className="grid grid-cols-6 gap-4 relative">
                {!isLoading && isFetching && <FetchingLoader />}
                {isLoading ? (
                    [...Array(12)].map((_, index) => <Skeleton key={index} sx={{ height: 226, transform: "none" }} />)
                ) : data.length ? (
                    data.map((project, index) => {
                        return (
                            <Link
                                to={`/dashboard/detail/project/${project.projects_id}`}
                                key={index}
                                className="bg-white p-4 rounded-md shadow-sm min-h-[226px] flex flex-col transition-all hover:shadow-card"
                            >
                                <div className="flex-1">
                                    <h4 className="text-black text-[16px] font-bold capitalize">
                                        {project.projects_name}
                                    </h4>
                                    <h5 className="text-black text-[14px] font-normal my-4">
                                        {project.customer?.customer_name ?? "-"}
                                    </h5>
                                    <p className="text-[#828282] text-[14px] font-normal line-clamp-3">
                                        {project.projects_description}
                                    </p>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <p className="text-[#828282] text-[14px] font-normal">#{index + 1}</p>
                                    <p className="text-black text-[14px] font-normal">
                                        {project?.quotations?.length ?? 0} Quote
                                    </p>
                                </div>
                            </Link>
                        );
                    })
                ) : (
                    <div className="col-span-6 flex items-center justify-center bg-white p-4 rounded-md shadow-sm min-h-[226px]">
                        <h4 className="text-black text-[18px] font-bold">
                            No projects found
                            {props.filters.search ? ` for "${props.filters.search}"` : ""}
                        </h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectList;
