export enum QuoteDrawerTabs {
    PRICE_LIST = "1",
    ADD_ITEM = "2",
    CROSS_REFERENCE = "3",
}

export enum AssetTypes {
    PROJECT = "project",
    QUOTATION = "quotation",
}
