import { useEffect, useState } from "react";
import { useGetProductsByCategoryQuery } from "shared/api";
import { errorToast } from "shared/utils";
import AddProductContentLoading from "./loading";

const ProductLists = (props: {
    selectedCategory: {
        parent: any;
        sub_category: any;
    };
    onClick: (item: any) => void;
}) => {
    const [catId, setCatId] = useState<any>(null);
    const { data, isLoading, error } = useGetProductsByCategoryQuery(catId, {
        skip: !catId,
    });
    const [products, setProducts] = useState<any[]>([]);

    useEffect(() => {
        if (!props.selectedCategory.sub_category && !props.selectedCategory.parent) return;
        let catId = null;
        if (!props.selectedCategory.sub_category) {
            catId = props.selectedCategory.parent.categories_id;
        } else {
            catId = props.selectedCategory.sub_category.categories_id;
        }
        setCatId(catId);
    }, [props.selectedCategory]);

    useEffect(() => {
        if (data) setProducts(data);
        error && errorToast("Failed to fetch products");
    }, [data, error]);

    if (!props.selectedCategory.parent && !props.selectedCategory.sub_category) {
        return <></>;
    }

    if (isLoading) return <AddProductContentLoading label="Model" />;

    return (
        <div>
            <label htmlFor="">Model:</label>
            {products.length ? (
                <div className="grid grid-cols-8 gap-2 text-[16px] leading-5 text-black relative left-[-10px] w-[calc(100%+20px)]">
                    {products.map((item: any, index) => (
                        <Item
                            key={index}
                            image={item.products_image}
                            label={item.products_model}
                            onClick={() => {
                                props.onClick({
                                    ...item,
                                    quantity: 1,
                                });
                            }}
                        />
                    ))}
                </div>
            ) : (
                <div className="mt-2">No products found</div>
            )}
        </div>
    );
};

export default ProductLists;

const Item = (props: { label: string; onClick?: () => void; image: string }) => {
    return (
        <div className="p-2 cursor-pointer" onClick={props.onClick}>
            <div className="h-[120px] w-full bg-black rounded-md">
                <img
                    src={`https://www.espenus.com/images/${props.image}`}
                    className="h-[120px] w-full object-contain rounded-md"
                    alt=""
                />
            </div>
            <h6 className="mt-2">{props.label}</h6>
        </div>
    );
};
