import { ArrowDropDown } from "@mui/icons-material";
import { MenuItem, Popover } from "@mui/material";
import { DownloadIcon, PlusIcon, SaveIcon, ShuffleIcon } from "assets/index";
import { MouseEvent, useState } from "react";
import { useParams } from "react-router-dom";
import {
    useExportQuoteRequestEXCELMutation,
    useExportQuoteRequestPDFMutation,
    useSaveQuoteRequestMutation,
} from "shared/api";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import Button from "shared/components/ui/Button";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import useAssetType from "shared/hooks/useAssetType";
import { useAppSelector } from "shared/store";
import { errorToast, successToast } from "shared/utils";

const QuoteActions = () => {
    const { id } = useParams();
    const { isProject } = useAssetType();
    const selectedProject = useAppSelector((state) => state.project.selected);
    const { setDrawerOpen, isQuoteChanged, setIsQuoteChanged } = useQuoteDrawer();
    const [saveQuote, { isLoading }] = useSaveQuoteRequestMutation();
    const { selectedQuotation, setSelectedQuotation } = useProjectDetailContext();
    const [exportPdf, pdfRes] = useExportQuoteRequestPDFMutation();
    const [exportExcel, excelRes] = useExportQuoteRequestEXCELMutation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    const getExportedData = () => {
        if (!selectedQuotation) return;

        return {
            ...selectedQuotation,
            freight_terms: {
                freight_value: selectedProject?.freight_terms || selectedQuotation.freight_terms?.freight_value,
            },
            project: selectedProject
                ? {
                      projects_name: selectedProject?.projects_name,
                  }
                : undefined,
        };
    };

    const pdfExport = () => {
        if (!selectedQuotation?.DocNum) return;
        exportPdf(getExportedData()).then((res: any) => {
            if (res.error) {
                errorToast("Error exporting quote");
            } else {
                const fileUrl = res.data.file_url;

                // Fetch the file content as a blob
                fetch(fileUrl)
                    .then((response) => response.blob())
                    .then((blob) => {
                        // Create a blob URL for the blob
                        const blobUrl = URL.createObjectURL(blob);

                        // Create a new anchor element
                        const link = document.createElement("a");

                        // Set the href attribute to the blob URL
                        link.href = blobUrl;

                        // Set the download attribute to specify the file name
                        link.setAttribute("download", "quotation.pdf");

                        // Append the link to the document body
                        document.body.appendChild(link);

                        // Programmatically click the link to initiate the download
                        link.click();

                        // Remove the link and revoke the blob URL after the download
                        link.addEventListener("click", function () {
                            document.body.removeChild(link);
                            URL.revokeObjectURL(blobUrl);
                        });
                    })
                    .catch((error) => {
                        console.error("Error fetching the file:", error);
                    });
            }
        });
    };

    const excelExport = () => {
        if (!selectedQuotation?.DocNum) return;
        exportExcel(getExportedData()).then((res: any) => {
            if (res.error) {
                errorToast("Error exporting quote");
            } else {
                const fileUrl = res.data.file_url;
                const link = document.createElement("a");
                link.href = fileUrl;
                link.setAttribute("download", "quotation.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    const onSave = () => {
        saveQuote({
            ...selectedQuotation,
            projects_id: id && isProject ? parseInt(id) : undefined,
        }).then((res: any) => {
            if (!res.error) {
                setIsQuoteChanged(false);
                setSelectedQuotation({
                    ...selectedQuotation,
                    ...res.data,
                    newQuote: false,
                });
                successToast("Quote saved successfully");
            } else {
                errorToast("Error saving quote");
            }
        });
    };

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
                <Button
                    onClick={() =>
                        setDrawerOpen({
                            open: true,
                            type: "add",
                        })
                    }
                    startIcon={<PlusIcon color="#ffffff" />}
                    color="primary"
                    size="large"
                >
                    Add Item
                </Button>
                <Button
                    onClick={() =>
                        setDrawerOpen({
                            open: true,
                            type: "xref",
                        })
                    }
                    color="primary"
                    startIcon={<ShuffleIcon />}
                    size="large"
                    className="!bg-primary-light !text-primary"
                >
                    Cross Reference
                </Button>
            </div>
            <div className="flex items-center space-x-4">
                <Button
                    loading={isLoading}
                    loadingText="Saving..."
                    color="primary"
                    startIcon={<SaveIcon />}
                    size="large"
                    disabled={!isQuoteChanged}
                    onClick={onSave}
                    className={!isQuoteChanged ? "!bg-primary-light !text-primary" : ""}
                >
                    Save
                </Button>
                {!selectedQuotation?.newQuote ? (
                    <Button
                        color="primary"
                        loadingText="Exporting..."
                        loading={pdfRes.isLoading || excelRes.isLoading}
                        startIcon={<DownloadIcon />}
                        endIcon={pdfRes.isLoading || excelRes.isLoading ? <></> : <ArrowDropDown />}
                        size="large"
                        onClick={handleClick}
                        className="!bg-primary-light !text-primary !pr-3"
                    >
                        Export
                    </Button>
                ) : (
                    <></>
                )}
            </div>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    className: "!rounded-md",
                }}
                sx={{
                    "& .MuiMenuItem-root": {
                        border: "1px solid #E5E7EB",

                        ":hover": {
                            color: "#000000 !important",
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        excelExport();
                    }}
                >
                    MS - Excel
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        handleClose();
                        pdfExport();
                    }}
                >
                    PDF
                </MenuItem>
            </Popover>
        </div>
    );
};

export default QuoteActions;
