import { CircularProgress } from "@mui/material";
import { twMerge } from "tailwind-merge";

const FetchingLoader = (props: { className?: string }) => {
    return (
        <div
            className={twMerge(
                "absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 flex items-center justify-center",
                props.className,
            )}
        >
            <div className="rounded-[30px] bg-white shadow-lg h-[60px] w-[60px] flex items-center justify-center">
                <CircularProgress size={24} />
            </div>
        </div>
    );
};

export default FetchingLoader;
