import { LogoRevo } from "assets/index";

const NotFound = () => {
    return (
        <div className="text-center">
            <div style={{ marginTop: "15vh" }} className="flex items-center justify-center flex-col">
                <LogoRevo height={80} width={80} className="mb-10" />
                <h3 className="text-6xl font-bold text-gray-400">404 page not found</h3>
                <p className="text-2xl font-bold text-gray-400" style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                    We are sorry but the page you are looking for does not exist.
                </p>
            </div>
        </div>
    );
};

export default NotFound;
