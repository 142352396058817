import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { usePostQuoteRequestMutation } from "shared/api";
import { errorToast, successToast } from "shared/utils";
import yup from "shared/utils/yup";
import CustomerSelect from "./Selectbox/customer-selectbox";
import FrieghtTermsSelect from "./Selectbox/frieght-terms-selectbox";
import PaymentTermsSelect from "./Selectbox/payment-terms-selectbox";
import { Field } from "./form";
import ThemeFormProvider from "./form/provider";
import Button from "./ui/Button";
import ThemeModal from "./ui/ThemeModal";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
    name: yup.string().required(),
    expiration_date: yup.string().required(),
    quotation_date: yup.string().required(),
    customer_id: yup.object().typeError("This field is required").required(),
    payment_term: yup.object().typeError("This field is required").required(),
    freight_term: yup.object().typeError("This field is required").required(),
});

const StartQuotationModal = (props: { open: boolean; onClose: () => void }) => {
    const form = useForm({
        resolver: yupResolver(schema),
    });
    const navigate = useNavigate();
    const [postQuotation, { data, error, isLoading }] = usePostQuoteRequestMutation();

    const onSubmit = (data) => {
        postQuotation({
            id: undefined,
            name: data.name,
            expiration_date: dayjs(data.expiration_date).format("YYYY-MM-DD"),
            quotation_date: dayjs(data.quotation_date).format("YYYY-MM-DD"),
            customer_id: data.customer_id.customer_code,
            customer_name: data.customer_id.customer_name,
            payment_terms: data.payment_term.id,
            freight_terms: data.freight_term.id,
        })
            .then((res: any) => {
                if (res.error) {
                    errorToast(res.error);
                    return;
                }
                navigate(`/dashboard/detail/quotation/${res.data.DocNum}`);
                successToast("Quotation created successfully");
                props.onClose();
            })
            .catch((err) => {
                errorToast(err);
            });
    };

    const quoteDate = form.watch("quotation_date");

    return (
        <ThemeModal
            header={{
                title: "New Quotation",
            }}
            open={props.open}
            onClose={() => props.onClose()}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit}>
                <div className="flex flex-col space-y-5">
                    <Field.Text label="Quotation Name" name="name" placeholder="Enter quotation name" />
                    <Field.Date label="Quotation Date" name="quotation_date" />
                    <Field.Date
                        label="Expiration Date"
                        name="expiration_date"
                        datePickerProps={{
                            shouldDisableDate: (date) => {
                                return date < dayjs(quoteDate).toDate();
                            },
                        }}
                    />
                    <CustomerSelect />
                    <FrieghtTermsSelect />
                    <PaymentTermsSelect />
                </div>
                <div className="grid grid-cols-12 gap-4 mt-10">
                    <Button disabled={isLoading} className="col-span-2" size="large" onClick={() => props.onClose()}>
                        Close
                    </Button>
                    <Button loading={isLoading} className="col-span-10" size="large" color="primary" type="submit">
                        Start Quotation
                    </Button>
                </div>
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default StartQuotationModal;
