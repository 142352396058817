import { Chip, ChipProps } from "@mui/material";
import { XIcon } from "assets/index";
import { omit } from "lodash";
import { twMerge } from "tailwind-merge";

const ThemeChip = (
    props: { color: ChipProps["color"] | "light-primary" } & Pick<ChipProps, "clickable" | "label" | "onDelete">,
) => {
    return (
        <Chip
            {...omit(props, ["color"] as const)}
            color={props.color === "light-primary" ? "primary" : props.color}
            classes={{
                root: twMerge(props.color === "light-primary" && "!bg-[#007EFF1A] !text-primary"),
            }}
            deleteIcon={<XIcon height={20} width={20} />}
        />
    );
};

export default ThemeChip;
