import { CircularProgress, IconButton, TextField } from "@mui/material";
import { BackButtonIcon, EditIcon, SearchIcon } from "assets/index";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProjectsQuery } from "shared/api";
import Button from "shared/components/ui/Button";
import ThemeSelect from "shared/components/ui/ThemeSelect";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import { errorToast } from "shared/utils";

const Filter = (props: { onEdit?: () => void }) => {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const context = useProjectDetailContext();
    const searchedValue = searchParams.get("search") || "";
    const { data, isLoading } = useProjectsQuery(undefined);
    const navigate = useNavigate();

    const currentProject = data?.find((project) => project.projects_id === parseFloat(id ?? "0"));

    const onChange = (e: any) => {
        const project = data?.find((project) => project.projects_id === e.target.value)?.projects_id;
        if (!project) {
            errorToast("Project not found");
        }

        navigate(`/dashboard/detail/project/${project}`, {
            replace: true,
        });
    };

    return (
        <div className="flex items-center gap-3 mb-3">
            <Link to="/dashboard">
                <IconButton className="!h-12 !w-12">
                    <BackButtonIcon />
                </IconButton>
            </Link>
            <div className="relative w-full">
                {isLoading ? (
                    <div className="absolute top-0 left-0 w-full h-full z-10 bg-white rounded-md flex items-center justify-center">
                        <CircularProgress size={30} color="primary" />
                    </div>
                ) : (
                    <ThemeSelect
                        options={data.map((project) => ({
                            label: project.projects_name,
                            value: project.projects_id,
                        }))}
                        selectProps={{
                            endAdornment: (
                                <div className="pl-2 border-l">
                                    <Button
                                        onClick={() => {
                                            if (props.onEdit) {
                                                props.onEdit();
                                            }
                                        }}
                                        className="!min-w-0 h-10"
                                        variant="text"
                                        color="primary"
                                        size="small"
                                    >
                                        <EditIcon color="#007EFF" />
                                    </Button>
                                </div>
                            ),
                            onChange,
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                            },
                            classes: {
                                root: "!bg-white",
                                icon: "!mr-14",
                            },
                            defaultValue: currentProject?.projects_id || "",
                        }}
                    />
                )}
            </div>
            <TextField
                sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                }}
                placeholder="Search and filter..."
                InputProps={{
                    startAdornment: <SearchIcon color="#828282" />,
                }}
                defaultValue={searchedValue}
                onChange={(e) => {
                    context.setSelectedQuotation(undefined);
                    if (e.target.value === "") {
                        navigate(".", {
                            replace: true,
                        });
                    } else {
                        navigate(`?search=${e.target.value}`, {
                            replace: true,
                        });
                    }
                }}
                fullWidth
            />
        </div>
    );
};

export default Filter;
