import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, IconButton, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAddProductContext } from "../context";
import ProductSelectionField from "./product-selection-field";

const AddProductFilter = () => {
    const addProductContent = useAddProductContext();
    const [quantity, setQuantity] = useState(1);
    const [filterOpen, setFilterOpen] = useState(false);
    const [suggestPrice, setSuggestPrice] = useState(0);

    const handleQuantityChange = (e) => {
        addProductContent.setSelectedProduct((prev) => ({
            ...addProductContent.selectedProduct,
            quantity: e.target.value,
        }));
        setQuantity(e.target.value);
    };

    const handleChangeField = (field, value) => {
        addProductContent.setSelectedProduct((prev) => ({
            ...prev,
            [field]: parseFloat(value) || 0,
        }));
    };

    const product = addProductContent?.selectedProduct;
    const changed = addProductContent?.changedOptions;

    useEffect(() => {
        if (!product) return;
        const LineOverage = suggestPrice - addProductContent?.selectedProductBasePrice;
        handleChangeField("LineOverage", LineOverage);
    }, [suggestPrice]);

    useEffect(() => {
        setSuggestPrice(addProductContent?.selectedProductPrice || 0);
    }, [addProductContent?.selectedProductPrice]);

    const defaultOpt = useMemo(() => {
        return product?.fields?.map((field) => {
            const changedField = changed.find((op) => op.id === field.id);
            return {
                ...field,
                value: changedField?.field || field.value,
            };
        });
    }, [product, changed]);

    const description =
        useMemo(
            () =>
                defaultOpt
                    ?.map((f) => f?.value?.description)
                    .filter((f) => f)
                    .join(","),
            [defaultOpt],
        ) || "";

    return (
        <>
            <div className="h-20 py-4 px-6 flex items-center space-x-6 text-[18px] leading-5 text-black shadow-[0px_1px_2px_0px_#0000001A] mb-[1px] bg-white">
                <div className="grid grid-cols-2 gap-6">
                    <div className="flex items-center space-x-2 whitespace-nowrap">
                        <label>Model:</label>
                        <ProductSelectionField onChange={(e) => {}} value={null} />
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        <div className="flex items-center space-x-2 whitespace-nowrap">
                            <label>Quantity:</label>
                            <TextField placeholder="0 pcs" value={quantity} onChange={handleQuantityChange} />
                        </div>
                        <div className="flex items-center space-x-2 whitespace-nowrap">
                            <label>Suggested Price:</label>
                            <TextField
                                placeholder="0.00"
                                onChange={(e) => setSuggestPrice(parseFloat(e.target.value) || 0)}
                                value={suggestPrice}
                                InputProps={{
                                    startAdornment: "$",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <IconButton onClick={() => setFilterOpen(!filterOpen)} className="!h-12 !w-12">
                    {filterOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>
            <Collapse in={filterOpen}>
                <Box className="shadow-[0px_1px_2px_0px_#0000001A] bg-white py-4 px-6">
                    <div className="grid grid-cols-[300px,1fr] gap-4">
                        <TextField disabled value={description || "-"} label="Description" />
                        <div className="grid grid-cols-6 gap-4">
                            <TextField
                                value={"$" + addProductContent.selectedProductHotPrice ?? "-"}
                                label="Hot Price"
                                disabled
                            />
                            <TextField
                                value={"$" + addProductContent.selectedProductNormalPrice ?? "-"}
                                label="Normal Price"
                                disabled
                            />
                            <TextField
                                value={"$" + addProductContent.selectedProductBasePrice ?? "-"}
                                label="Base Price"
                                disabled
                            />
                            <TextField
                                value={`$${(
                                    (addProductContent.selectedProductBasePrice / 100) *
                                        (product?.LineNewCommission || 0) || 0
                                ).toFixed(2)}`}
                                label="Commission"
                                disabled
                            />
                            <TextField value={product?.LineOverage || 0} label="Overage" disabled />
                            <TextField
                                value={"$" + addProductContent.selectedProductLineTotal ?? "0"}
                                label="Line Total"
                                disabled
                            />
                        </div>
                    </div>
                </Box>
            </Collapse>
        </>
    );
};

export default AddProductFilter;
