import { Person } from "@mui/icons-material";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import { MenuList } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "shared/store";
import { removeUser } from "shared/store/slices";
import { successToast } from "shared/utils";

const UserDropdown = () => {
    const { info } = useAppSelector((state) => state.user);
    const firstInitial = info?.username[0].toUpperCase();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogout = () => {
        dispatch(removeUser());
        localStorage.removeItem("revo_token");
        successToast("Logged out successfully");
        navigate("/");
    };

    return (
        <React.Fragment>
            <div className="!h-10 !w-10 !rounded-[40px] overflow-hidden mx-auto relative" style={{ zIndex: open ? 10000 : 1 }}>
                <IconButton
                    onClick={handleClick}
                    className="!h-10 !w-10 !rounded-[48px]"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <Person />
                </IconButton>
            </div>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                disablePortal
                onClose={handleClose}
                onClick={handleClose}
                sx={{
                    ".MuiBackdrop-root": {
                        backgroundColor: "rgba(132, 137, 147, 0.7)",
                        backdropFilter: "blur(4px)",
                    },
                }}
                PaperProps={{
                    boxShadow: "0px 1px 2px 0px #0000001A !important",
                    sx: {
                        borderRadius: "8px !important",
                        minWidth: 200,
                        overflow: "visible",
                        mt: "-10px",
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            bottom: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <MenuList className="!p-0" dense>
                    <div className="flex items-center space-x-2 capitalize !px-4 !pt-1 !pb-3">
                        <div className="h-10 w-10 bg-primary text-white text-[16px] leading-[40px] font-bold rounded-full flex items-center justify-center">
                            {firstInitial}
                        </div>
                        <div className="flex flex-col">
                            <span>{info?.username}</span>
                            <span className="text-[12px] text-gray-500">{info?.authority}</span>
                        </div>
                    </div>
                    <Divider className="!mb-2" />
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            onLogout();
                        }}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </MenuList>
            </Menu>
        </React.Fragment>
    );
};

export default UserDropdown;
