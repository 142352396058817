import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";

interface Props extends DialogProps {
    header?: {
        title: string;
        closeIcon?: boolean;
        onClose?: () => void;
    };
}

const ThemeModal = ({ ...props }: Props) => {
    const handleClose = (e: any) => {
        if (props.onClose) {
            props.onClose(e, "backdropClick");
        }
    };

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="sm">
            {props.header && (
                <DialogTitle className="flex items-center justify-between !pb-5">
                    <span className="text-black text-[24px] leading-8 font-bold">{props.header?.title}</span>
                    <IconButton onClick={props.header?.onClose ?? handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent className="!pt-5 !pb-10">{props.children}</DialogContent>
        </Dialog>
    );
};

export default ThemeModal;
