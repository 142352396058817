import PriceListItem from "./components/item";
import PriceListHeader from "./header";
import usePriceListContent from "./hook";
import PriceListLoading from "./loader";

const PriceListContent = (props: { canAddProduct: boolean }) => {
    const hook = usePriceListContent();

    return (
        <div className="relative h-screen flex flex-col">
            <PriceListHeader
                columns={hook.columnNames || []}
                onSortChange={(val) => hook.setSort(val as any)}
                selectedColumns={hook.selectedColumns}
                onChangeColumns={(val) => hook.setSelectedColumns(val)}
                onChangeSearch={(val) => hook.setSearch(val)}
                onChangeFilters={(type, value) => {
                    const newFilters = hook.filters.filter((filter) => filter.type !== type);
                    if (value) newFilters.push({ type, value });
                    hook.setFilters(newFilters);
                }}
            />
            <div className="p-6 flex-1 flex flex-col space-y-6 relative">
                {hook.isLoading ? <PriceListLoading /> : <></>}
                {hook.products
                    .sort((a, b) => {
                        const aPrice = a.fields.reduce((acc: number, field: any) => {
                            return acc + parseFloat(field.options.find((f: any) => f.default)?.base_price || 0);
                        }, 0);

                        const bPrice = b.fields.reduce((acc: number, field: any) => {
                            return acc + parseFloat(field.options.find((f: any) => f.default)?.base_price || 0);
                        }, 0);

                        if (hook.sort === "asc") return a.name.localeCompare(b.name);
                        if (hook.sort === "desc") return b.name.localeCompare(a.name);
                        if (hook.sort === "price-asc") return aPrice - bPrice;
                        if (hook.sort === "price-desc") return bPrice - aPrice;
                        return 0;
                    })
                    .map((product, index) => {
                        return (
                            <div key={index}>
                                <PriceListItem
                                    selectedColumns={hook.selectedColumns}
                                    product={product}
                                    canAddProduct={props.canAddProduct}
                                />
                            </div>
                        );
                    })}
                {!hook.isLoading && hook.isFetching ? <PriceListLoading /> : <></>}
            </div>
        </div>
    );
};

export default PriceListContent;
