import { UpRightArrowIcon } from "assets/index";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import Button from "shared/components/ui/Button";
import ThemeChip from "shared/components/ui/ThemeChip";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import { useAddProductContext } from "../context";

const AddProductCardTitle = () => {
    const drawer = useQuoteDrawer();
    const context = useAddProductContext();
    const quoteDetail = useProjectDetailContext();

    const onApply = () => {
        const changed = context.changedOptions;

        const record = context.selectedProduct.fields.map((field) => {
            const changedField = changed.find((op) => op.id === field.id);
            return {
                ...field,
                value: changedField?.field || field.value,
            };
        });

        const optionWithValue = record.filter((f) => Object.keys(f.value).length);

        const description = optionWithValue
            .filter((f) => f && f.value.description)
            .map((f) => f.value.description)
            .join(",");

        const catalogNumber = optionWithValue
            .filter((f) => f && f.value.name)
            .map((f) => f.value.name)
            .join("/");

        const fieldTotal = record.reduce((acc, field) => {
            return acc + parseFloat(field.value?.normal_price || 0);
        }, 0);

        if (quoteDetail.selectedQuotation) {
            quoteDetail.setSelectedQuotation({
                ...quoteDetail.selectedQuotation,
                detail: [
                    ...quoteDetail.selectedQuotation.detail,
                    {
                        LineNum: (quoteDetail.selectedQuotation?.detail?.length || 0) + 1,
                        GenericModel: catalogNumber,
                        Description: description,
                        Quantity: context.selectedProduct?.quantity,
                        Price: fieldTotal,
                        LineNewCommission: context.selectedProduct?.LineNewCommission || 0,
                        LineOverage: context.selectedProduct?.LineOverage || 0,
                        LineTotal: context.selectedProductLineTotal || 0,
                    },
                ],
            });
        }

        drawer.setIsQuoteChanged(true);

        drawer.setDrawerOpen((prev) => ({
            ...prev,
            open: false,
        }));
    };

    return (
        <div className="flex items-center space-x-4">
            <span>Customization:</span>
            <div className="flex-1 flex justify-between items-center">
                <div className="flex items-center space-x-3">
                    {context.selectedCategory.parent || context.selectedCategory.sub_category ? (
                        <>
                            {context.selectedCategory.parent && (
                                <ThemeChip
                                    label={context.selectedCategory.parent.cat_description?.categories_name}
                                    color="light-primary"
                                    onDelete={() => {
                                        context.setSelectedCategory({
                                            parent: null,
                                            sub_category: null,
                                        });
                                        context.setSelectedProduct(null);
                                    }}
                                />
                            )}
                            {context.selectedCategory.sub_category && (
                                <ThemeChip
                                    label={context.selectedCategory.sub_category.cat_description?.categories_name}
                                    color="light-primary"
                                    onDelete={() => {
                                        context.setSelectedCategory((prev) => ({
                                            ...prev,
                                            sub_category: null,
                                        }));
                                        context.setSelectedProduct(null);
                                    }}
                                />
                            )}
                            {context.selectedProduct && (
                                <ThemeChip
                                    label={context.selectedProduct.products_model}
                                    color="light-primary"
                                    onDelete={() => context.setSelectedProduct(null)}
                                />
                            )}
                            <div
                                onClick={() => {
                                    context.setSelectedCategory({
                                        parent: null,
                                        sub_category: null,
                                    });
                                    context.setSelectedProduct(null);
                                }}
                                className="!underline text-primary text-[14px]"
                            >
                                Clear All
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {context.selectedProduct && (
                <Button size="large" color="primary" onClick={onApply} startIcon={<UpRightArrowIcon />}>
                    Add To Quote
                </Button>
            )}
        </div>
    );
};

export default AddProductCardTitle;
