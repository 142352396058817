import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

yup.setLocale({
    mixed: {
        required: "This field is required",
    },
});

export default yup;

export { yupResolver };
