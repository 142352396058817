import { Skeleton } from "@mui/material";
import React from "react";

const AddProductContentLoading = (props: { label: string }) => {
    return (
        <div>
            <label htmlFor="">{props.label}:</label>
            <div className="grid grid-cols-8 gap-2 text-[16px] leading-5 text-black relative left-[-10px] w-[calc(100%+20px)]">
                {[...Array(8)].map((item: any, index) => (
                    <div className="p-2">
                        <Skeleton key={index} className="rounded-md" variant="rectangular" width={120} height={120} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AddProductContentLoading;
