import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { useQuoteRequestsQuery } from "shared/api";
import { DashboardFilters } from "..";
import FetchingLoader from "../../../shared/components/ui/FetchingLoader";

const QuotationList = (props: { filters: DashboardFilters }) => {
    const { data, isLoading, isFetching, error } = useQuoteRequestsQuery({
        startDate: props.filters.dateRange.value?.[0].toISOString(),
        endDate: props.filters.dateRange.value?.[1].toISOString(),
        search: props.filters.search ?? "",
    });

    if (error) {
        console.log(error);
    }

    return (
        <div>
            <div className="flex items-center justify-between my-4">
                <h4 className="text-black text-[18px] font-bold">My Quotations ({data?.length ?? "0"})</h4>
            </div>
            <div className="grid grid-cols-6 gap-4 relative">
                {!isLoading && isFetching && <FetchingLoader />}
                {isLoading ? (
                    [...Array(12)].map((_, index) => <Skeleton key={index} sx={{ height: 226, transform: "none" }} />)
                ) : data?.length ? (
                    data?.map((quote, index) => {
                        return (
                            <Link
                                to={`/dashboard/detail/quotation/${quote.DocNum}`}
                                key={index}
                                className="bg-white p-4 rounded-md shadow-sm min-h-[226px] flex flex-col transition-all hover:shadow-card"
                            >
                                <div className="flex-1">
                                    <h4 className="text-black text-[16px] font-bold capitalize">
                                        {quote.Name || `Quote ${quote.DocNum}-${quote.Version}`}
                                    </h4>
                                    <h5 className="text-black text-[14px] font-normal my-4">
                                        {quote.customer?.customer_name ?? "-"}
                                    </h5>
                                    <p className="text-[#828282] text-[14px] font-normal line-clamp-3">
                                        {quote.Description}
                                    </p>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <p className="text-[#828282] text-[14px] font-normal">#{index + 1}</p>
                                </div>
                            </Link>
                        );
                    })
                ) : (
                    <div className="col-span-6 flex items-center justify-center bg-white p-4 rounded-md shadow-sm min-h-[226px]">
                        <h4 className="text-black text-[18px] font-bold">
                            No quotations found {props.filters.search ? ` for "${props.filters.search}"` : ""}
                        </h4>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuotationList;
