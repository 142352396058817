import { ExpandMore } from "@mui/icons-material";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { RangeType } from "rsuite/esm/DatePicker";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { DashboardFilters } from "..";

const predefinedBottomRanges: RangeType<DateRange>[] = [
    {
        label: "Today",
        value: [new Date(), new Date()],
    },
    {
        label: "Yesterday",
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    },
    {
        label: "This week",
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
    },
    {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
    },
    {
        label: "Last 30 days",
        value: [subDays(new Date(), 29), new Date()],
    },
    {
        label: "This month",
        value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
        label: "Last month",
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    },
    {
        label: "This year",
        value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
    },
];

const DashboardDateRangePicker = (
    props: DashboardFilters["dateRange"] & {
        onChange: (value: DashboardFilters["dateRange"]) => void;
    },
) => {
    return (
        <DateRangePicker
            ranges={predefinedBottomRanges as any}
            caretAs={ExpandMore}
            label={props.label + ":"}
            onChange={(value: any) => {
                props.onChange({
                    label: "Custom",
                    value,
                });
            }}
            value={props.value}
            format="MMMM dd, yyyy"
            onShortcutClick={(shortcut: any, event) => {
                props.onChange({
                    label: shortcut.label,
                    value: shortcut.value,
                });
            }}
        />
    );
};

export default DashboardDateRangePicker;
