import { ArrowDropDown } from "@mui/icons-material";
import {
    Box,
    IconButton,
    Popover,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { useAddProductContext } from "../context";

const ProductCustomization = (props: { fields: any; loading: boolean }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openedOption, setOpenedOption] = useState<any>(null);
    const addPContext = useAddProductContext();
    const [tableWidth, setTableWidth] = useState(0);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    if (!props.fields && !props.loading) {
        return <div>No Product Selected</div>;
    }

    const onItemClick = (field: any) => {
        addPContext.setChangedOptions(openedOption.id, field);
        setAnchorEl(null);
    };

    return (
        <div>
            {props.loading ? (
                <div className="px-6 grid grid-cols-[200px,1fr] gap-x-2">
                    {[...Array(10)].map((_, index) => (
                        <>
                            <Skeleton key={index} height={60} />
                            <Skeleton key={index} height={60} />
                        </>
                    ))}
                </div>
            ) : (
                <CustomTable
                    id="customization-table"
                    border={1}
                    sx={{
                        "& td": {
                            "&:first-child": {
                                "&:before": {
                                    content: "''",
                                    display: "block",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    right: "11px",
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0px 1px 4px 0px #0000001A",
                                    width: `calc(100% - 200px - 22px)`,
                                    height: "calc(100% - 22px)",
                                    borderRadius: "8px",
                                },
                            },
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell width={"200px"}>Feature</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Hot Price</TableCell>
                            <TableCell>Normal Price</TableCell>
                            <TableCell>Base Price</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.fields
                            .filter((field: any) => field.options.length)
                            ?.map((field: any, index: number) => {
                                const defaultOption =
                                    addPContext.changedOptions?.find((item) => item.id === field.id)?.field ??
                                    field.options.find((option: any) => option.default === 1) ??
                                    field.options[0];

                                return (
                                    <TableRow>
                                        <TableCell>
                                            <span>{field.name}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>{field.options.length ? defaultOption.name : "-"}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>{field.options.length ? defaultOption.description : "-"}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>
                                                {field.options.length ? "$" + (defaultOption.hot_price || 0) : "-"}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span>
                                                {field.options.length ? "$" + (defaultOption.normal_price || 0) : "-"}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <span>
                                                {field.options.length ? "$" + (defaultOption.base_price || 0) : "-"}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(e) => {
                                                    handleClick(e);
                                                    setOpenedOption(field);
                                                }}
                                                className="!p-0 !bg-transparent"
                                            >
                                                <ArrowDropDown className="!text-[#000000]" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </CustomTable>
            )}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                TransitionProps={{
                    // get table width
                    onEntering: () => {
                        const width = document.getElementById("customization-table")?.clientWidth;
                        setTableWidth(width ?? 0);
                    },
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    sx: {
                        borderRadius: "10px",
                    },
                }}
            >
                <Box
                    sx={{
                        minWidth: tableWidth - 60,
                    }}
                >
                    <Table>
                        <TableBody>
                            {openedOption?.options.length ? (
                                openedOption?.options?.map((field: any, index: number) => {
                                    const defaultOption = field.default ?? 0;

                                    return (
                                        <TableRow sx={{ cursor: "pointer" }} className="hover:!bg-gray-100">
                                            <TableCell onClick={() => onItemClick(field)}>{field.name}</TableCell>
                                            <TableCell onClick={() => onItemClick(field)}>
                                                {field.name} {defaultOption ? <>Default</> : <></>}
                                            </TableCell>
                                            <TableCell onClick={() => onItemClick(field)}>
                                                {field.description}
                                            </TableCell>
                                            <TableCell onClick={() => onItemClick(field)}>
                                                {"$" + (field.hot_price || 0)}
                                            </TableCell>
                                            <TableCell onClick={() => onItemClick(field)}>
                                                {"$" + (field.normal_price || 0)}
                                            </TableCell>
                                            <TableCell onClick={() => onItemClick(field)}>
                                                {"$" + (field.base_price || 0)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <div className="p-2">No Options</div>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </Popover>
        </div>
    );
};

export default ProductCustomization;

const CustomTable = styled(Table)(({ theme }) => {
    return {
        borderSpacing: "0",
        borderCollapse: "collapse",
        width: "100%",
        "& tr": {
            position: "relative",

            "& th": {
                padding: "19px 24px",
                textAlign: "left",
                fontWeight: 600,
                fontSize: "16px",
                backgroundColor: "#FFFFFF",

                "&:first-child": {
                    borderRight: "1px solid #D5D5D5",
                },
            },
            "& td": {
                padding: "26px 24px",
                borderBottom: "1px solid #D5D5D5",
                fontSize: "16px",
                backgroundColor: "#F5F6F7",

                span: {
                    position: "relative",
                },

                "&:first-child": {
                    borderRight: "1px solid #D5D5D5",
                    backgroundColor: "#FFFFFF",
                },
            },
        },
    };
});
