import { Box, IconButton, styled } from "@mui/material";
import { BackButtonIcon, FolderIcon, PlusIcon } from "assets/index";
import { Link } from "react-router-dom";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import useAssetType from "shared/hooks/useAssetType";
import { twMerge } from "tailwind-merge";

const QuoteNav = (props: { quotations: any[]; selected: number; onAddQuote: () => void }) => {
    const context = useProjectDetailContext();
    const { isQuotation } = useAssetType();

    const quotationWithNames = props.quotations.map((quote, index) => {
        const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let quoteName = `${alpha[index]}`;
        if (index > 25) {
            quoteName = `${alpha[Math.floor(index / 26) - 1]}${alpha[index % 26]}`;
        }

        return {
            ...quote,
            customName: `Quote ${quoteName}`,
        };
    });

    return (
        <CustomBox>
            <ul className="flex items-center space-x-2 overflow-auto">
                {isQuotation && (
                    <Link to="/dashboard">
                        <IconButton className="!h-12 !w-12 !rounded-b-none">
                            <BackButtonIcon />
                        </IconButton>
                    </Link>
                )}
                {quotationWithNames.map((quote, index) => {
                    return (
                        <li
                            key={index}
                            className={twMerge(quote.DocNum === props.selected ? "active" : "", "whitespace-nowrap capitalize")}
                            onClick={() => context.setSelectedQuotation(quote)}
                        >
                            <FolderIcon />
                            <span>{quote.Name || quote.customName}</span>
                        </li>
                    );
                })}
                {!isQuotation && (
                    <li onClick={() => props.onAddQuote()}>
                        <PlusIcon />
                    </li>
                )}
            </ul>
        </CustomBox>
    );
};

export default QuoteNav;

const CustomBox = styled(Box)({
    paddingLeft: "8px",

    li: {
        boxShadow: "0px 1px 4px 0px #0000001A",
        background: "#FBFBFB",
        borderRadius: "8px 8px 0px 0px",
        padding: "12px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
        color: "#828282",
        fontWeight: 700,

        path: {
            stroke: "#828282",
        },

        "&.active": {
            color: "#000000",
            background: "#FFFFFF",

            path: {
                stroke: "#000000",
            },
        },

        ":not(:last-child)": {
            paddingRight: "48px",
        },
    },
});
