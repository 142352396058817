import { Button } from "@mui/material";
import { PriceListIcon } from "assets/index";
import { matchPath, useLocation } from "react-router-dom";
import { useQuoteDrawer } from "../QuoteDrawerWrapper";

const Header = () => {
    const { setDrawerOpen } = useQuoteDrawer();
    const location = useLocation();
    const matched = matchPath("/dashboard/detail/:type/:id", location.pathname);

    return (
        <div className="h-12 flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
                <h4 className="text-black text-[24px] leading-8">Revo Quotation</h4>
            </div>
            <div className="flex items-center space-x-4">
                <Button
                    size="large"
                    onClick={() => {
                        setDrawerOpen({
                            open: true,
                            type: !matched ? "only_price_list" : "price_list",
                        });
                    }}
                    startIcon={<PriceListIcon height={20} width={20} />}
                >
                    Price List
                </Button>
            </div>
        </div>
    );
};

export default Header;
