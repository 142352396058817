import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
    Autocomplete,
    Box,
    Checkbox,
    Collapse,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    TextField,
} from "@mui/material";
import { SearchIcon } from "assets/index";
import { useState } from "react";
import { useProductFiltersQuery } from "shared/api";
import ThemeSelect from "shared/components/ui/ThemeSelect";
import { twMerge } from "tailwind-merge";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PriceListHeader = (props: {
    columns: any[];
    selectedColumns: string[];
    onSortChange: (value: string) => void;
    onChangeColumns: (value: string[]) => void;
    onChangeSearch: (value: string) => void;
    onChangeFilters: (type: string, value: string) => void;
}) => {
    const { data, isLoading } = useProductFiltersQuery(undefined);
    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <Box>
            <div className="min-h-[80px] py-4 px-6 flex items-center gap-6 text-[18px] leading-5 text-black bg-white shadow-[0px_1px_2px_0px_#0000001A] mb-[1px]">
                <TextField
                    fullWidth
                    placeholder="Search products by manufacturer, catalog number, or description..."
                    variant="outlined"
                    InputProps={{
                        startAdornment: <SearchIcon color="#828282" />,
                    }}
                    onChange={(e) => props.onChangeSearch(e.target.value)}
                />
                <div className="w-[400px]">
                    <Autocomplete
                        multiple
                        limitTags={0}
                        id="multiple-limit-tags"
                        options={props.columns}
                        getOptionLabel={(option) => option}
                        sx={{
                            width: "400px",
                            overflow: "hidden",
                            ".MuiFormControl-root": {
                                paddingRight: "0px !important",
                            },
                        }}
                        onChange={(_, value) => props.onChangeColumns(value)}
                        defaultValue={props.selectedColumns}
                        value={props.selectedColumns}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} className={twMerge(props.className, "!py-0 !text-md flex")}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option}
                            </li>
                        )}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                className="!pr-4"
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        ".MuiIconButton-root": { backgroundColor: "white !important" },
                                    },
                                }}
                                placeholder={props.selectedColumns.length ? "Selected Columns" : "Select Columns"}
                            />
                        )}
                    />
                </div>
                <div className="min-w-[150px]">
                    <ThemeSelect
                        selectProps={{
                            startAdornment: <div className="whitespace-nowrap opacity-50">Sort By:</div>,
                            sx: {
                                boxShadow: "none !important",
                            },
                            defaultValue: "asc",
                            onChange: (e) => props.onSortChange(e.target.value as string),
                        }}
                        options={[
                            { label: "A-Z", value: "asc" },
                            { label: "Z-A", value: "desc" },
                            { label: "Price: Low to High", value: "price-asc" },
                            { label: "Price: High to Low", value: "price-desc" },
                        ]}
                    />
                </div>
                <IconButton onClick={() => setFilterOpen(!filterOpen)} className="!h-12 !w-12">
                    {filterOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>
            <Collapse in={filterOpen}>
                <Box
                    sx={{
                        paddingRight: "96px",
                    }}
                    className="shadow-[0px_1px_2px_0px_#0000001A] bg-white py-4 px-6"
                >
                    <div className="grid grid-cols-7 gap-4">
                        <TypeSelect
                            selectProps={{
                                label: "Type",
                                onChange: (e) => props.onChangeFilters("Type", e.target.value as string),
                            }}
                            options={data?.types}
                        />
                        <ThemeSelect
                            selectProps={{
                                label: "Watts/Lumens",
                                onChange: (e) => props.onChangeFilters("Wattage/Lumens", e.target.value as string),
                            }}
                            options={
                                data?.wattageLumens
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_description,
                                        value: item.options_name,
                                    })) || []
                            }
                        />
                        <ThemeSelect
                            options={
                                data?.cct
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_description,
                                        value: item.options_name,
                                    })) || []
                            }
                            selectProps={{
                                label: "CCT",
                                onChange: (e) => props.onChangeFilters("CCT", e.target.value as string),
                            }}
                        />
                        <ThemeSelect
                            options={
                                data?.cri
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_description,
                                        value: item.options_name,
                                    })) || []
                            }
                            selectProps={{
                                label: "CRI",
                                onChange: (e) => props.onChangeFilters("CRI", e.target.value as string),
                            }}
                        />
                        <ThemeSelect
                            options={
                                data?.voltage
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_description,
                                        value: item.options_name,
                                    })) || []
                            }
                            selectProps={{
                                label: "Voltage",
                                onChange: (e) => props.onChangeFilters("Voltage", e.target.value as string),
                            }}
                        />
                        <ThemeSelect
                            options={
                                data?.finish
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_description,
                                        value: item.options_name,
                                    })) || []
                            }
                            selectProps={{
                                label: "Finish",
                                onChange: (e) => props.onChangeFilters("Finish", e.target.value as string),
                            }}
                        />
                        <ThemeSelect
                            options={
                                data?.mounting
                                    ?.filter((f) => f.options_description)
                                    .map((item) => ({
                                        label: item.options_name,
                                        value: item.options_name,
                                    })) || []
                            }
                            selectProps={{
                                label: "Mounting",
                                onChange: (e) => props.onChangeFilters("Mounting", e.target.value as string),
                            }}
                        />
                    </div>
                </Box>
            </Collapse>
        </Box>
    );
};

export default PriceListHeader;

const TypeSelect = (props: {
    placeholder?: string;
    options: any[];
    selectProps?: SelectProps & {
        errorMessages?: string;
    };
}) => {
    const options =
        props.options?.reduce((acc, curr) => {
            if (curr.subcategories) {
                return [...acc, curr, ...curr.subcategories];
            }
            return [...acc, curr];
        }, []) || [];

    return (
        <FormControl fullWidth error={props.selectProps?.error}>
            <InputLabel id="demo-simple-select-helper-label">{props.selectProps?.label}</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={props.selectProps?.label}
                fullWidth
                variant="outlined"
                {...props.selectProps}
            >
                {options.map((option, index) => (
                    <MenuItem
                        className={twMerge("!py-1", option.subcategories && "!font-bold")}
                        key={index}
                        value={option.id}
                        sx={
                            !option.subcategories
                                ? {
                                      paddingTop: "3px !important",
                                      paddingBottom: "3px !important",
                                      paddingLeft: "32px !important",
                                  }
                                : {}
                        }
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            {props.selectProps?.error && <FormHelperText error>{props.selectProps.errorMessages}</FormHelperText>}
        </FormControl>
    );
};
