import React, { PropsWithChildren } from "react";
import ThemePerfectScrollbar from "shared/components/ui/ThemePerfectScrollbar";
import { twMerge } from "tailwind-merge";

const QuoteCard = (
    props: PropsWithChildren & {
        title: string | React.ReactNode;
        labelClassName?: string;
        childrenClassName?: string;
    },
) => {
    return (
        <ThemePerfectScrollbar className="bg-white shadow-[0px_1px_4px_0px_#00000029] rounded-md overflow-hidden">
            <div className="h-20 flex items-center text-[18px] leading-5 text-black border-b border-[#D5D5D5] px-6">
                <div className={twMerge("w-full", props.labelClassName)}>{props.title}</div>
            </div>
            <div className={twMerge("p-6", props.childrenClassName)}>{props.children}</div>
        </ThemePerfectScrollbar>
    );
};

export default QuoteCard;
