import { useProjectDetailContext } from "shared/context/project-detail-context";
import QuoteActions from "./QuoteActions";
import QuoteDescription from "./QuoteDescription";
import QuoteTable from "./QuoteTable";

const QuoteTab = () => {
    const context = useProjectDetailContext();

    if (context.selectedQuotation === undefined) {
        return (
            <div className="p-9 flex items-center justify-center bg-[#FBFBFB] border border-[#D5D5D5] border-dashed rounded-md">
                <p className="text-[#6B7280]">No Quotation Selected</p>
            </div>
        );
    }

    return (
        <div className="shadow-[0px_1px_4px_0px_#0000001A] bg-white rounded-md pb-10">
            <div className="p-4 flex flex-col space-y-4">
                <QuoteDescription />
                <QuoteActions />
            </div>
            <QuoteTable />
        </div>
    );
};

export default QuoteTab;
