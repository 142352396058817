import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, TextField } from "@mui/material";
import { RightArrowIcon, ShuffleIcon, UploadIcon } from "assets/index";
import { useEffect, useMemo, useState } from "react";
import {
    useAddCrossReferenceMutation,
    useGetProductDetailQuery,
    useGetRevoProductsQuery,
    useManufacturersQuery,
} from "shared/api";
import InlineField from "shared/components/ui/InlineField";
import ThemeSelect from "shared/components/ui/ThemeSelect";
import { errorToast, successToast } from "shared/utils";
import { twMerge } from "tailwind-merge";
import QuoteCard from "../../QuoteCard";

const NewReference = (props: { onCancel: () => void }) => {
    const [expanded, setExpanded] = useState(false);
    const { data, isLoading } = useManufacturersQuery(undefined);
    const { data: products, isLoading: productLoading } = useGetRevoProductsQuery(undefined);
    const [fields, setFields] = useState<any>();
    const [addCrossReference, addRes] = useAddCrossReferenceMutation();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const { data: detailData } = useGetProductDetailQuery(selectedProduct?.products_id, {
        skip: !selectedProduct,
    });

    // form related states
    const [model, setModel] = useState<string>("");
    const [selectedField, setSelectedField] = useState<
        {
            fieldLabel: string;
            optionId: number;
            value: string;
        }[]
    >([]);
    const [partNumber, setPartNumber] = useState<string>("");
    const [selectedManufacturer, setSelectedManufacturer] = useState<number>();
    const [specSheet, setSpecSheet] = useState<string>("");
    const [specFile, setSpecFile] = useState<File>();

    useEffect(() => {
        if (!detailData?.error) {
            setFields(detailData?.fields ?? []);
        }
    }, [detailData]);

    const changeField = (fieldLabel: string, value: string) => {
        setSelectedField((prev) =>
            prev.map((f) => {
                if (f.fieldLabel === fieldLabel) {
                    return {
                        ...f,
                        value,
                    };
                }
                return f;
            }),
        );
    };

    const onChangeOption = (fieldLabel: string, optionId: number) => {
        setSelectedField((prev) =>
            prev.map((f) => {
                if (f.fieldLabel === fieldLabel) {
                    return {
                        ...f,
                        optionId,
                    };
                }
                return f;
            }),
        );
    };

    const defaultOpt = useMemo(() => {
        return fields?.filter((f: any) => f.options.length).map((f) => f.options.find((o: any) => o.default)) ?? [];
    }, [fields]);

    useEffect(() => {
        setSelectedField(
            fields
                ?.filter((f: any) => f.options.length)
                .map((f) => ({
                    fieldLabel: f.name,
                    optionId: f.options.find((o) => o.default)?.id,
                    value: "",
                })) ?? [],
        );
    }, [defaultOpt]);

    const onSubmit = () => {
        if (!selectedManufacturer) {
            return errorToast("Please select a manufacturer");
        }
        if (!model || !selectedProduct) {
            return errorToast("Please enter a product model");
        }
        const formdata = new FormData();
        formdata.append("model", model);
        formdata.append("manufacturer_id", selectedManufacturer.toString());
        formdata.append("part_number", partNumber);
        formdata.append("fields", JSON.stringify(selectedField));
        formdata.append("spec_url", specSheet);
        formdata.append("spec_file", specFile ?? "");
        addCrossReference(formdata).then((res: any) => {
            if (res.data?.error) {
                return errorToast(res.data.error);
            }
            props.onCancel();
            successToast("Cross reference added successfully");
        });
    };

    const catalogNumber = useMemo(() => {
        // get fields options
        const fieldsOptions = selectedField
            .map((f) => {
                const field = fields?.find((field) => field.name === f.fieldLabel);
                if (!field) return "";
                const option = field.options?.find((o) => o.id === f.optionId);
                return option?.name || option?.description || "";
            })
            .filter((f) => f);

        return fieldsOptions.join("/");
    }, [fields, selectedField]);

    return (
        <QuoteCard
            labelClassName="text-center w-full text-[24px] leading-8 text-[#25282B] !font-semibold"
            title="Add A New Cross Reference Item "
        >
            <Box className="flex flex-col space-y-4">
                <GroupField
                    left={<p className="text-center">1. Input your product to cross reference here:</p>}
                    right={<p className="text-center">2. Select your Revo equivalent here:</p>}
                    showArrow={false}
                    className="!bg-transparent"
                />
                <GroupField
                    left={
                        <ThemeSelect
                            placeholder="Select"
                            options={
                                data
                                    ?.filter((m) => m.manufacturers_name !== "Revo")
                                    .map((m) => ({ label: m.manufacturers_name, value: m.manufacturers_id })) ?? []
                            }
                            selectProps={{
                                label: "Manufacturer",
                                onChange: (e) => setSelectedManufacturer(e.target.value as number),
                            }}
                        />
                    }
                    right={
                        <TextField
                            label="Manufacturer"
                            onChange={(e) => setModel(e.target.value)}
                            placeholder="Enter a value"
                            value={"Revo Lighting"}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    }
                />
                <GroupField
                    left={
                        <TextField
                            label="Fixture Series"
                            onChange={(e) => setModel(e.target.value)}
                            placeholder="Enter a value"
                            value={model}
                        />
                    }
                    right={
                        <ThemeSelect
                            placeholder="Select"
                            selectProps={{
                                label: "Fixture Series",
                                onChange: (e) =>
                                    setSelectedProduct(products?.find((m) => m.products_id === e.target.value)),
                            }}
                            options={products?.map((m) => ({ label: m.products_model, value: m.products_id })) ?? []}
                        />
                    }
                />
                <GroupField
                    left={
                        <TextField
                            label="Part Number"
                            placeholder="Enter a value"
                            onChange={(e) => setPartNumber(e.target.value)}
                            value={partNumber}
                        />
                    }
                    right={
                        <TextField
                            label="Part Number"
                            value={catalogNumber}
                            InputProps={{
                                readOnly: true,
                            }}
                            placeholder="Enter a value"
                        />
                    }
                />
                <Collapse className={expanded ? "" : "!mt-0"} in={expanded}>
                    <div className="flex flex-col space-y-4">
                        {fields
                            ?.filter((field) => field.name !== "Fixture Series")
                            .filter((field) => field.options?.length > 0)
                            .map((field: any) => {
                                return (
                                    <GroupField
                                        key={field.id}
                                        left={
                                            <TextField
                                                label={field.name}
                                                value={selectedField.find((f) => f.fieldLabel === field.name)?.value}
                                                onChange={(e) => changeField(field.name, e.target.value)}
                                                placeholder="Enter a value"
                                            />
                                        }
                                        right={
                                            <ThemeSelect
                                                placeholder="Select"
                                                options={field?.options?.map((option) => ({
                                                    label: option.name || option.description,
                                                    value: option.id,
                                                }))}
                                                selectProps={{
                                                    label: field.name,
                                                    defaultValue: field?.options?.find((o) => o.default)?.id,
                                                    onChange: (e) =>
                                                        onChangeOption(field.name, e.target.value as number),
                                                }}
                                            />
                                        }
                                    />
                                );
                            })}
                    </div>
                </Collapse>
                {fields?.length > 0 && (
                    <div className="flex items-center justify-end">
                        <Button
                            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                            onClick={() => setExpanded(!expanded)}
                            fullWidth={false}
                            className="!underline !uppercase !font-bold !p-0"
                            disableRipple
                            variant="text"
                            color="primary"
                        >
                            {expanded ? "Hide All" : "Show All"}
                        </Button>
                    </div>
                )}
                <div>
                    <h5 className="text-center mb-4">3. Add your spec sheet from URL or upload a PDF here:</h5>
                    <div className="bg-[#F5F6F7] p-4 flex flex-col space-y-4 rounded-md">
                        <InlineField className="grid-cols-[400px,1fr]" label="Spec Sheet URL">
                            <TextField
                                onChange={(e) => {
                                    setSpecSheet(e.target.value);
                                    setSpecFile(undefined);
                                }}
                                placeholder="Type or paste a URL"
                            />
                        </InlineField>
                        <div className="relative flex items-center justify-center text-[#828282] text-[18px] leading-5 font-semibold">
                            <span className="w-full h-[1px] bg-[#D5D5D5] absolute top-1/2 translate-y-1/2"></span>
                            <div className="text-center relative z-10 bg-[#F5F6F7] inline px-4">or</div>
                        </div>
                        <div className="relative border-2 border-[#D5D5D5] border-dashed p-[34px] flex items-center justify-center flex-col space-y-4 rounded-md bg-white">
                            <input
                                type="file"
                                accept=".pdf"
                                onChange={(e) => {
                                    setSpecSheet("");
                                    setSpecFile(e.target.files?.[0]);
                                }}
                                className="opacity-0 absolute top-0 left-0 w-full h-full"
                            />
                            <UploadIcon />
                            <div className="text-center">
                                <h6>Upload Spec Sheet .PDF</h6>
                                {specFile ? (
                                    <p className="text-gray-500 mt-1">Selected File: {specFile.name}</p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
            <div className="flex items-start justify-between mt-10">
                <Button onClick={props.onCancel} size="large">
                    Cancel
                </Button>
                <Button size="large" onClick={onSubmit} startIcon={<ShuffleIcon />} color="primary">
                    Save To Database
                </Button>
            </div>
        </QuoteCard>
    );
};

export default NewReference;

const GroupField = (props: {
    left: React.ReactNode;
    right: React.ReactNode;
    showArrow?: boolean;
    className?: string;
}) => {
    return (
        <Box
            sx={{
                ".MuiFormControl-root": {
                    height: "auto !important",
                },
                textarea: {
                    paddingTop: "0 !important",
                },
            }}
            className={twMerge("flex items-center p-3 bg-[#F5F6F7] rounded-md", props.className)}
        >
            <div className="flex-1">{props.left}</div>
            <div className="px-3 w-[64px] flex items-center justify-center">
                {(props.showArrow ?? true) && <RightArrowIcon />}
            </div>
            <div className="flex-1">{props.right}</div>
        </Box>
    );
};
