import { Delete } from "@mui/icons-material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDeleteProjectMutation, usePostProjectMutation } from "shared/api";
import { useAppSelector } from "shared/store";
import { errorToast, successToast } from "shared/utils";
import yup, { yupResolver } from "shared/utils/yup";
import CustomerSelect from "./Selectbox/customer-selectbox";
import FrieghtTermsSelect from "./Selectbox/frieght-terms-selectbox";
import PaymentTermsSelect from "./Selectbox/payment-terms-selectbox";
import { Field } from "./form";
import ThemeFormProvider from "./form/provider";
import Button from "./ui/Button";
import ThemeModal from "./ui/ThemeModal";

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    customer_id: yup.object().typeError("This field is required").required(),
    payment_term: yup.object().typeError("This field is required").required(),
    freight_term: yup.object().typeError("This field is required").required(),
});

const EditProjectModal = (props: { open: boolean; onClose: () => void }) => {
    const navigate = useNavigate();
    const [deleteProject, { isLoading: deleteLoading }] = useDeleteProjectMutation();
    const selectedProject = useAppSelector((state) => state.project.selected);
    const form = useForm({
        resolver: yupResolver(schema),
    });

    const [postProject, { isLoading }] = usePostProjectMutation();

    const onSubmit = (data) => {
        postProject({
            id: selectedProject?.projects_id,
            name: data.name,
            description: data.description,
            customer_id: data.customer_id.customer_code,
            customer_name: data.customer_id.customer_name,
            payment_terms: data.payment_term.value,
            freight_terms: data.freight_term.value,
        })
            .then((res: any) => {
                if (res.error) throw new Error(res.error.message);
                successToast("Project created successfully");
                props.onClose();
            })
            .catch((err) => {
                errorToast(err);
            });
    };

    const onDelete = () => {
        if (!selectedProject) return;

        deleteProject(selectedProject.projects_id).then(() => {
            successToast("Project deleted successfully");
            navigate("/dashboard");
            props.onClose();
        });
    };

    useEffect(() => {
        if (!selectedProject || !props.open) return;

        form.reset({
            name: selectedProject.projects_name,
            description: selectedProject.projects_description,
            customer_id: {
                customer_code: selectedProject.customer?.customer_code,
                customer_name: selectedProject.customer?.customer_name,
            },
            payment_term: {
                value: selectedProject.payment_terms,
                label: selectedProject.payment_terms,
            },
            freight_term: {
                value: selectedProject.freight_terms,
                label: selectedProject.freight_terms,
            },
        });
    }, [selectedProject, props.open]);

    return (
        <ThemeModal
            header={{
                title: "Edit Project",
            }}
            open={props.open}
            onClose={() => props.onClose()}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit}>
                <div className="flex flex-col space-y-5">
                    <Field.Text label="Name" name="name" placeholder="Enter project name" />
                    <Field.Text label="Description" name="description" placeholder="Enter a description..." />
                    <CustomerSelect />
                    <FrieghtTermsSelect />
                    <PaymentTermsSelect />
                </div>
                <div className="flex space-x-4 mt-10">
                    <Button
                        color="error"
                        variant="outlined"
                        className="!bg-[#FF0F000D]"
                        size="large"
                        loading={deleteLoading}
                        onClick={onDelete}
                        startIcon={<Delete />}
                    >
                        Delete
                    </Button>
                    <Button disabled={deleteLoading} size="large" onClick={() => props.onClose()}>
                        Close
                    </Button>
                    <Button
                        disabled={deleteLoading}
                        loading={isLoading}
                        size="large"
                        color="primary"
                        type="submit"
                        fullWidth
                    >
                        Save
                    </Button>
                </div>
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default EditProjectModal;
