import { twMerge } from "tailwind-merge";
import QuoteCard from "../../QuoteCard";
import AddProductCardTitle from "./components/card-title";
import AddProductFilter from "./components/filter";
import AddProductContentLoading from "./components/loading";
import ProductCustomization from "./components/product-customization";
import ProductLists from "./components/product-lists";
import { AddProductContext } from "./context";
import useAddProductContent from "./hook";

const AddProductContent = () => {
    const hook = useAddProductContent();

    return (
        <AddProductContext
            value={{
                selectedCategory: hook.selectedCategory,
                setSelectedCategory: hook.setSelectedCategory,
                selectedProduct: hook.selectedProduct,
                setSelectedProduct: hook.setSelectedProduct,
                productFields: hook.productFields,
                changedOptions: hook.changedOptions,
                setChangedOptions: hook.onChangeOption,
                selectedProductPrice: hook.selectedProductPrice,
                selectedProductLineTotal: hook.selectedProductLineTotal,
                selectedProductHotPrice: hook.selectedProductHotPrice,
                selectedProductNormalPrice: hook.selectedProductNormalPrice,
                selectedProductBasePrice: hook.selectedProductBasePrice,
            }}
        >
            <AddProductFilter />
            <div className="p-6">
                <QuoteCard
                    title={<AddProductCardTitle />}
                    childrenClassName={twMerge("flex flex-col space-y-4", hook.selectedProduct ? "p-0" : "")}
                >
                    {hook.loading ? (
                        <AddProductContentLoading label="Fixture" />
                    ) : (
                        <>
                            {hook.selectedProduct ? (
                                <ProductCustomization fields={hook.productFields} loading={hook.isLoading} />
                            ) : (
                                <>
                                    <div>
                                        <label htmlFor="">Fixture:</label>
                                        <div className="grid grid-cols-8 gap-2 text-[16px] leading-5 text-black relative left-[-10px] w-[calc(100%+20px)]">
                                            {hook.categoriesList().map((item: any, index) => (
                                                <Item
                                                    key={index}
                                                    image={item.categories_image}
                                                    label={item.cat_description?.categories_name}
                                                    onClick={() => hook.onClickItem(item)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <ProductLists
                                        onClick={hook.setSelectedProduct}
                                        selectedCategory={hook.selectedCategory}
                                    />
                                </>
                            )}
                        </>
                    )}
                </QuoteCard>
            </div>
        </AddProductContext>
    );
};

export default AddProductContent;

const Item = (props: { label: string; onClick?: () => void; image: string }) => {
    return (
        <div className="p-2 cursor-pointer" onClick={props.onClick}>
            <div className="h-[120px] w-full bg-black rounded-md">
                <img
                    src={`https://www.espenus.com/images/${props.image}`}
                    className="h-[120px] w-full object-contain rounded-md"
                    alt=""
                />
            </div>
            <h6 className="mt-2">{props.label}</h6>
        </div>
    );
};
