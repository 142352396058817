import { PropsWithChildren } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";

const ThemeFormProvider = (
    props: PropsWithChildren & {
        form: UseFormReturn<any>;
        className?: string;
        onSubmit: ((onValid: any, onInvalid?: any) => void) | null;
    },
) => {
    return (
        <FormProvider {...props.form}>
            <form
                onSubmit={
                    props.onSubmit
                        ? props.form.handleSubmit(props.onSubmit)
                        : (e) => {
                              e.preventDefault();
                          }
                }
                autoComplete="off"
                className={props.className}
            >
                {props.children}
            </form>
        </FormProvider>
    );
};

export default ThemeFormProvider;
