// Need to use the React-specific entry point to import createApi
import { setProject } from "shared/store/slices";
import { api } from "./api";

const projectApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // projects
        projects: builder.query({
            query: (query?: { search?: string; startDate?: string; endDate?: string }) => {
                const url = new URLSearchParams();
                if (query) {
                    const { search, startDate, endDate } = query;
                    if (search) url.append("search", search);
                    if (startDate) url.append("startDate", startDate);
                    if (endDate) url.append("endDate", endDate);
                }
                return { url: `projects?${url.toString()}` };
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ project_id }) => ({ type: "Project", id: project_id }) as const),
                          { type: "Project", id: "LIST" },
                      ]
                    : [{ type: "Project", id: "LIST" }],
        }),
        getProjectById: builder.query({
            query: (id: string) => ({ url: `projects/${id}` }),
            providesTags: (result, error) => (error ? [] : [{ type: "Project", id: result?.projects_id }]),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(setProject(data));
                    } else {
                        throw new Error("No data found");
                    }
                } catch (error) {}
            },
        }),
        postProject: builder.mutation({
            query: ({ id, ...project }) => ({
                url: id ? `projects/${id}` : "projects",
                method: "POST",
                body: project,
            }),
            invalidatesTags: (_, error, response) =>
                error
                    ? []
                    : [
                          { type: "Project", id: "LIST" },
                          { type: "Project", id: response?.project_id },
                      ],
        }),
        //delete project
        deleteProject: builder.mutation({
            query: (id: string) => ({ url: `projects/${id}`, method: "DELETE" }),
            invalidatesTags: [{ type: "Project", id: "LIST" }],
        }),
    }),
    overrideExisting: false,
});

export const { useProjectsQuery, usePostProjectMutation, useGetProjectByIdQuery, useDeleteProjectMutation } =
    projectApi;
