import { Button } from "@mui/material";
import { PlusIcon } from "assets/index";
import { useState } from "react";
import DefaultContent from "./DefaultContent";
import MatchFound from "./MatchFound";
import NewReference from "./NewReference";
import NoMatchFound from "./NoMatchFound";
import SearchInput from "./SearchInput";

const CrossReferenceContent = () => {
    const [newReference, setNewReference] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [matchFounds, setMatchFounds] = useState<boolean>(false);

    return (
        <>
            <div className="h-20 py-4 px-6 flex items-center space-x-6 text-[18px] leading-5 text-black bg-white">
                <div className="flex items-center space-x-2 whitespace-nowrap flex-1">
                    <label>Reference Model:</label>
                    <SearchInput
                        onSelectProduct={(product) => {
                            setMatchFounds(true);
                            setSelectedProduct(product);
                        }}
                        onChange={(value) => {
                            setSearchValue(value);
                        }}
                        hasMatchFound={(value) => {
                            setMatchFounds(value);
                        }}
                        onClear={() => {
                            setSelectedProduct(null);
                            setSearchValue("");
                        }}
                    />
                </div>
                <Button
                    onClick={() => setNewReference(!newReference)}
                    className="!h-12 whitespace-nowrap"
                    startIcon={<PlusIcon />}
                >
                    Add New Item
                </Button>
            </div>
            <div className="p-6">
                {!newReference ? (
                    <>
                        {selectedProduct && matchFounds && <MatchFound product={selectedProduct} />}
                        {!selectedProduct && !searchValue && <DefaultContent />}
                        {!selectedProduct && searchValue && !matchFounds && <NoMatchFound value={searchValue} />}
                    </>
                ) : (
                    <NewReference onCancel={() => setNewReference(false)} />
                )}
            </div>
        </>
    );
};

export default CrossReferenceContent;
