// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const xrefApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // cross reference
        crossReference: builder.query({
            query: (search: string) => ({ url: "cross-reference", body: { search } }),
        }),
        crossReferenceDetail: builder.mutation({
            query: (body) => ({ url: `cross-reference/detail`, body, method: "POST" }),
        }),
        addCrossReference: builder.mutation({
            query: (body) => ({ url: `cross-reference/add`, body, method: "POST" }),
        }),
    }),
    overrideExisting: true,
});

export const { useCrossReferenceDetailMutation, useAddCrossReferenceMutation } = xrefApi;
