// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const categoriesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // cross reference
        categories: builder.query({
            query: () => ({ url: "categories" }),
        }),
    }),
    overrideExisting: true,
});

export const { useCategoriesQuery } = categoriesApi;
