// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const quotationApis = api.injectEndpoints({
    endpoints: (builder) => ({
        // quotes
        quoteRequests: builder.query({
            query: (query?: { search?: string; startDate?: string; endDate?: string }) => {
                const url = new URLSearchParams();
                if (query) {
                    const { search, startDate, endDate } = query;
                    if (search) url.append("search", search);
                    if (startDate) url.append("startDate", startDate);
                    if (endDate) url.append("endDate", endDate);
                }
                return { url: `quote-requests?${url.toString()}` };
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ DocNum }) => ({ type: "QuoteRequest", id: DocNum }) as const),
                          { type: "QuoteRequest", id: "LIST" },
                      ]
                    : [{ type: "QuoteRequest", id: "LIST" }],
        }),
        quoteRequestByID: builder.query({
            query: (id: string) => ({ url: `quote-requests/${id}` }),
        }),
        postQuoteRequest: builder.mutation({
            query: ({ id, ...quoteRequest }) => ({
                url: "quote-requests",
                method: "POST",
                body: quoteRequest,
            }),
            invalidatesTags: (result, error, quoteRequest) =>
                error
                    ? []
                    : [
                          { type: "Project", id: quoteRequest?.projects_id ?? "LIST" },
                          { type: "QuoteRequest", id: "LIST" },
                      ],
        }),
        saveQuoteRequest: builder.mutation({
            query: (quoteRequest) => ({
                url: "quote-requests/save",
                method: "POST",
                body: quoteRequest,
            }),
            invalidatesTags: (result, error, quoteRequest) => {
                return error
                    ? []
                    : [
                          { type: "Project", id: quoteRequest?.projects_id },
                          { type: "QuoteRequest", id: quoteRequest?.DocNum },
                          { type: "Project", id: "LIST" },
                          { type: "QuoteRequest", id: "LIST" },
                      ];
            },
        }),

        // delete
        deleteQuoteRequest: builder.mutation({
            query: (id: number) => ({
                url: `quote-requests/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Project", id: "LIST" },
                { type: "QuoteRequest", id: "LIST" },
            ],
        }),

        exportQuoteRequestPDF: builder.mutation({
            query: (quote) => ({
                url: `quote-requests/export/pdf`,
                method: "POST",
                body: quote,
            }),
        }),

        exportQuoteRequestEXCEL: builder.mutation({
            query: (quote) => ({
                url: `quote-requests/export/excel`,
                method: "POST",
                body: quote,
            }),
        }),
    }),
    overrideExisting: true,
});

export const {
    usePostQuoteRequestMutation,
    useQuoteRequestsQuery,
    useQuoteRequestByIDQuery,
    useSaveQuoteRequestMutation,
    useExportQuoteRequestPDFMutation,
    useExportQuoteRequestEXCELMutation,
    useDeleteQuoteRequestMutation,
} = quotationApis;
