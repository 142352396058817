import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

const InlineField = (
    props: PropsWithChildren & {
        label: string;
        className?: string;
    },
) => {
    return (
        <Box
            sx={{
                ".MuiTextField-root": {
                    boxShadow: "none",
                    textAlign: "right",
                    direction: "rtl",
                    height: "48px",
                },
                ".MuiInputBase-root": {
                    boxShadow: "none",
                    height: "48px",
                },
            }}
            className={twMerge("InlineField-root rounded-md py-4", props.className)}
        >
            <h5 className="text-[#828282] text-[18px] leading-6">{props.label}</h5>
            {props.children}
        </Box>
    );
};

export default InlineField;
