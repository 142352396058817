import { IconButton } from "@mui/material";
import { EditIcon } from "assets/index";
import moment from "moment";
import { useState } from "react";
import EditQuotationModal from "shared/components/EditQuotationModal";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import { Quotation } from "shared/types/quotation";

const QuoteDescription = () => {
    const context = useProjectDetailContext();
    const quotation = context.selectedQuotation as Quotation;
    const [open, setOpen] = useState(false);

    const customer = quotation.customer;

    return (
        <>
            <div className="grid grid-cols-2 border rounded-md text-[16px] leading-6">
                <div className="flex items-start border-r border-[#D5D5D5] p-4 gap-4">
                    <div>Quote To:</div>
                    <div className="grid grid-cols-2 flex-1">
                        <div>
                            <b>{customer?.customer_name || "-"}</b> <br />
                            {customer?.customer_address} <br />
                            {[customer?.customer_city, customer?.customer_zip, customer?.customer_country]
                                .filter(Boolean)
                                .join(", ") || "-"}
                        </div>
                        <div className="text-right pt-6">
                            <span>Phone: {customer?.customer_phone ?? "-"}</span>
                            <br />
                            <span>Fax: {customer?.customer_fax ?? "-"}</span>
                        </div>
                    </div>
                </div>
                <div className="p-4 flex items-center space-x-6">
                    <div className="grid-cols-2 grid gap-[50px] flex-1">
                        <div>
                            <div className="flex justify-between">
                                <span>Quotation Number: </span>
                                <span>{`${quotation.DocNum}-${quotation.Version}` ?? "-"}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Quotation Date: </span>
                                <span>
                                    {quotation.DocDate ? moment(quotation.DocDate ?? "").format("DD/MM/YYYY") : "-"}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Expiration Date: </span>
                                <span>
                                    {quotation.DocDueDate
                                        ? moment(quotation.DocDueDate ?? "").format("DD/MM/YYYY")
                                        : "-"}
                                </span>
                            </div>
                        </div>
                        <div>
                            <p>Description:</p>
                            <p className="text-[#828282] text-[14px] leading-4 mt-1">{quotation.Description ?? "-"}</p>
                        </div>
                    </div>
                    <IconButton
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <EditIcon color="#828282" />
                    </IconButton>
                </div>
            </div>
            <EditQuotationModal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </>
    );
};

export default QuoteDescription;
