import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from "@mui/material";
import { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useFreightTermsQuery } from "shared/api";

const FrieghtTermsSelect = () => {
    const [open, setOpen] = useState(false);
    const form = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name: "freight_term",
        control: form.control,
    });

    const { data, error, isLoading } = useFreightTermsQuery(undefined, {
        skip: open === false,
    });

    const isError = form.formState.errors.freight_term;

    return (
        <FormControl
            sx={{
                ".MuiButtonBase-root": {
                    backgroundColor: "transparent !important",
                },
            }}
            fullWidth
            error={isError ? true : false}
        >
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth
                value={value ?? null}
                onChange={(e, value) => {
                    onChange(value);
                }}
                id="freight-terms-select"
                options={data ?? []}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
                getOptionLabel={(option) => option.value}
                loading={isLoading}
                loadingText="Loading freight terms..."
                filterOptions={(options, params) => {
                    const filtered = options?.filter((option) => {
                        return option.value.toLowerCase().includes(params.inputValue.toLowerCase());
                    });

                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Freight Terms"
                        error={isError ? true : false}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {isError && <FormHelperText error>{(isError as any).message}</FormHelperText>}
        </FormControl>
    );
};

export default FrieghtTermsSelect;
