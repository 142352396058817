import { FormHelperText, FormLabel, Popover, TextField } from "@mui/material";
import { DateCalendar, DateCalendarProps } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

const DateField = (props: {
    label?: string;
    name: string;
    placeholder?: string;
    datePickerProps?: DateCalendarProps<any>;
}) => {
    const form = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const {
        field: { value, onChange },
    } = useController({
        name: props.name,
        control: form.control,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const error = form.formState.errors[props.name];

    return (
        <FormLabel
            sx={{
                "& .MuiIconButton-root": {
                    display: "none",
                },
            }}
            error={error ? true : false}
        >
            <TextField
                variant="outlined"
                fullWidth
                label={props.label}
                value={value ? dayjs(value).format("YYYY-MM-DD") : ""}
                placeholder="YYYY-MM-DD"
                onClick={(e) => {
                    handleClick(e);
                }}
                ref={inputRef}
                error={error ? true : false}
            />
            {error && (
                <FormHelperText error className="!ml-[14px]">
                    {(error as any).message}
                </FormHelperText>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <DateCalendar
                    sx={{
                        "& .MuiIconButton-root": {
                            backgroundColor: "transparent !important",
                        },
                    }}
                    value={value}
                    onChange={(newValue) => {
                        onChange(newValue);
                        handleClose();
                    }}
                    {...props.datePickerProps}
                />
            </Popover>
        </FormLabel>
    );
};

export default DateField;
