import { ZoomInIcon } from "assets/index";

const DefaultContent = () => {
    return (
        <div className="flex flex-col items-center justify-center max-w-[750px] mx-auto pt-[100px]">
            <div className="mb-4">
                <ZoomInIcon />
            </div>
            <h5 className="text-[18px] leading-5 text-[#25282B]">
                Find the Revo Lighting equivalent to any product on your schedule.
            </h5>
            <p className="text-[16px] leading-5 text-[#828282] text-center mt-5">
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Vivamus
                sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
            </p>
        </div>
    );
};

export default DefaultContent;
