import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { QuoteDrawerTabs } from "shared/utils";
import QuoteDrawer from "./utils/QuoteDrawer";

interface DrawerOptions {
    open: boolean;
    type: "add" | "xref" | "price_list" | "only_price_list";
}

interface Props {
    drawerOpen: DrawerOptions;
    setDrawerOpen: React.Dispatch<React.SetStateAction<DrawerOptions>>;
    tabValue: string;
    handleTabChange: (newValue: QuoteDrawerTabs) => void;
    productFromPriceList: {
        parent: any;
        sub_category: any;
    };
    setProductFromPriceList: React.Dispatch<
        React.SetStateAction<{
            parent: any;
            sub_category: any;
        }>
    >;
    selectedProduct: any;
    setSelectedProduct: React.Dispatch<React.SetStateAction<any>>;
    isQuoteChanged: boolean;
    setIsQuoteChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuoteDrawerContext = createContext<Props>({
    drawerOpen: {
        open: false,
        type: "add",
    },
    setDrawerOpen: () => {},
    tabValue: "1",
    handleTabChange: () => {},
    productFromPriceList: {
        parent: null,
        sub_category: null,
    },
    setProductFromPriceList: () => {},
    selectedProduct: null,
    setSelectedProduct: () => {},
    isQuoteChanged: false,
    setIsQuoteChanged: () => {},
});

export const useQuoteDrawer = () => useContext<Props>(QuoteDrawerContext);

const QuoteDrawerWrapper = (props: PropsWithChildren) => {
    const [tabValue, setTabValue] = useState(QuoteDrawerTabs.ADD_ITEM);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [isQuoteChanged, setIsQuoteChanged] = useState(false);
    const [productFromPriceList, setProductFromPriceList] = useState<{
        parent: any;
        sub_category: any;
    }>({
        parent: null,
        sub_category: null,
    }); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [drawerOpen, setDrawerOpen] = useState<DrawerOptions>({
        open: false,
        type: "add",
    });

    useEffect(() => {
        if (drawerOpen.open) {
            switch (drawerOpen.type) {
                case "add":
                    setTabValue(QuoteDrawerTabs.ADD_ITEM);
                    break;
                case "xref":
                    setTabValue(QuoteDrawerTabs.CROSS_REFERENCE);
                    break;
                case "price_list":
                    setTabValue(QuoteDrawerTabs.PRICE_LIST);
                    break;
                case "only_price_list":
                    setTabValue(QuoteDrawerTabs.PRICE_LIST);
                    break;
                default:
                    break;
            }
        }
    }, [drawerOpen.open]);

    const handleTabChange = (newValue: QuoteDrawerTabs) => {
        setTabValue(newValue);
    };

    return (
        <QuoteDrawerContext.Provider
            value={{
                drawerOpen,
                setDrawerOpen,
                tabValue,
                handleTabChange,
                productFromPriceList,
                setProductFromPriceList,
                selectedProduct,
                setSelectedProduct,
                isQuoteChanged,
                setIsQuoteChanged,
            }}
        >
            <QuoteDrawer
                open={drawerOpen.open}
                onClose={() =>
                    setDrawerOpen((prev) => ({
                        ...prev,
                        open: false,
                    }))
                }
            />
            {props.children}
        </QuoteDrawerContext.Provider>
    );
};

export default QuoteDrawerWrapper;
