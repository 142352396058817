import { Close } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Drawer, DrawerProps, IconButton, Tab } from "@mui/material";
import { PlusIcon, PriceListIcon, ShuffleIcon } from "assets/index";
import { QuoteDrawerTabs } from "shared/utils";
import { useQuoteDrawer } from "..";
import AddProductContent from "./Content/AddProductContent";
import CrossReferenceContent from "./Content/CrossReferenceContent";
import PriceListContent from "./Content/PriceListContent";

const QuoteDrawer = (props: DrawerProps) => {
    const { drawerOpen, tabValue, setDrawerOpen, handleTabChange } = useQuoteDrawer();

    return (
        <Drawer
            classes={{
                paper: "w-[1296px]",
            }}
            anchor={"right"}
            onTransitionExited={() => handleTabChange(QuoteDrawerTabs.ADD_ITEM)}
            {...props}
        >
            <TabContext value={tabValue}>
                <Box
                    sx={{
                        ".MuiTabPanel-root": {
                            width: "100%",
                            padding: 0,
                            background: "#F4F4F4",
                            paddingLeft: "64px",
                            height: "100vh",
                        },
                    }}
                    className="flex h-full"
                >
                    <TabList
                        onChange={(e, value) => handleTabChange(value)}
                        classes={{
                            root: "h-full bg-[#616162] !px-3 !py-4 fixed",
                            flexContainer: "flex flex-col space-y-3",
                            indicator: "!hidden",
                        }}
                        sx={{
                            ".MuiTab-root": {
                                minWidth: "auto",
                                padding: 0,

                                "&.Mui-selected .MuiIconButton-root": {
                                    color: "#007EFF",
                                },
                            },
                        }}
                    >
                        <Tab
                            label={
                                <IconButton>
                                    <Close />
                                </IconButton>
                            }
                            onClick={() => props.onClose && props.onClose({}, "backdropClick")}
                        />
                        <Tab
                            label={
                                <IconButton>
                                    <PlusIcon />
                                </IconButton>
                            }
                            className={drawerOpen.type === "only_price_list" ? "!hidden" : ""}
                            value={QuoteDrawerTabs.ADD_ITEM}
                        />
                        <Tab
                            label={
                                <IconButton>
                                    <ShuffleIcon />
                                </IconButton>
                            }
                            className={drawerOpen.type === "only_price_list" ? "!hidden" : ""}
                            value={QuoteDrawerTabs.CROSS_REFERENCE}
                        />
                        <Tab
                            label={
                                <IconButton
                                    onClick={() => {
                                        setDrawerOpen((prev) => {
                                            return { ...prev, type: "price_list" };
                                        });
                                    }}
                                >
                                    <PriceListIcon />
                                </IconButton>
                            }
                            value={QuoteDrawerTabs.PRICE_LIST}
                        />
                    </TabList>
                    {drawerOpen.type !== "only_price_list" ? (
                        <>
                            <TabPanel className="overflow-auto" value={QuoteDrawerTabs.ADD_ITEM}>
                                <AddProductContent />
                            </TabPanel>
                            <TabPanel className="overflow-auto" value={QuoteDrawerTabs.CROSS_REFERENCE}>
                                <CrossReferenceContent />
                            </TabPanel>
                        </>
                    ) : (
                        <></>
                    )}
                    <TabPanel className="overflow-auto" value={QuoteDrawerTabs.PRICE_LIST}>
                        <PriceListContent canAddProduct={drawerOpen.type === "price_list"} />
                    </TabPanel>
                </Box>
            </TabContext>
        </Drawer>
    );
};

export default QuoteDrawer;
