import { Autocomplete, CircularProgress, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import { useGetProductsByCategoryQuery } from "shared/api";
import { useAddProductContext } from "../context";

const ProductSelectionField = (props: {
    value: any;
    onChange?: (value: any) => void;
    variant?: "outlined" | "standard";
}) => {
    const { selectedProduct, setSelectedProduct } = useAddProductContext();

    const [open, setOpen] = useState(false);
    const { data: cData, isLoading: loading } = useGetProductsByCategoryQuery(undefined, {
        skip: open === false,
    });

    return (
        <FormControl
            sx={{
                ".MuiButtonBase-root": {
                    backgroundColor: "transparent !important",
                },
            }}
            fullWidth
        >
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth
                value={selectedProduct ?? null}
                onChange={(e, value) => {
                    setSelectedProduct({
                        ...value,
                        quantity: 1,
                    });
                }}
                id="product-select"
                options={cData?.filter((v, i, a) => a.findIndex((t) => t.products_id === v.products_id) === i) ?? []}
                isOptionEqualToValue={(option: any, value) => option.products_id === value.products_id}
                getOptionLabel={(option) => option.products_model}
                loading={loading}
                loadingText="Loading..."
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Fixture Model"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default ProductSelectionField;
