import { LogoRevo } from "assets/index";
import { twMerge } from "tailwind-merge";

interface PageLoaderProps {
    className?: string;
}

const PageLoader = ({ className = "" }: PageLoaderProps) => {
    return (
        <div
            className={twMerge(
                `h-screen w-full flex items-center justify-center bg-[rgba(255,255,255,0.5)] fixed top-0 left-0 z-[10000]`,
                className,
            )}
        >
            <div className="text-center flex flex-col justify-center items-center">
                <LogoRevo />
                <div className="linear-loader mt-5">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default PageLoader;
