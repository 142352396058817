import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteQuoteRequestMutation, useSaveQuoteRequestMutation } from "shared/api";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import useAssetType from "shared/hooks/useAssetType";
import { useAppSelector } from "shared/store";
import { Quotation } from "shared/types/quotation";
import { errorToast, successToast } from "shared/utils";
import yup from "shared/utils/yup";
import CustomerSelect from "./Selectbox/customer-selectbox";
import FrieghtTermsSelect from "./Selectbox/frieght-terms-selectbox";
import PaymentTermsSelect from "./Selectbox/payment-terms-selectbox";
import { Field } from "./form";
import ThemeFormProvider from "./form/provider";
import Button from "./ui/Button";
import ThemeModal from "./ui/ThemeModal";

const schema = yup.object().shape({
    name: yup.string(),
    expiration_date: yup.string().required(),
    quotation_date: yup.string().required(),
    customer_id: yup.object().typeError("This field is required").required(),
    payment_term: yup.object().typeError("This field is required").required(),
    freight_term: yup.object().typeError("This field is required").required(),
    description: yup.string(),
});

const EditQuotationModal = (props: { open: boolean; onClose: () => void }) => {
    const { id } = useParams();
    const { isProject } = useAssetType();
    const [deleteQuote, { isLoading: deleteLoading }] = useDeleteQuoteRequestMutation();
    const [saveQuote, { isLoading }] = useSaveQuoteRequestMutation();
    const { selected: project } = useAppSelector((state) => state.project);
    const context = useProjectDetailContext();
    const navigate = useNavigate();
    const form = useForm({
        resolver: yupResolver(schema),
        context: {
            projects_id: project?.projects_id,
        },
    });

    useEffect(() => {
        if (props.open) {
            console.log(context.selectedQuotation?.DocDate, 'context.selectedQuotation?.DocDate');
            
            form.reset({
                name: context.selectedQuotation?.Name || "",
                expiration_date: context.selectedQuotation?.DocDueDate
                    ? (dayjs(context.selectedQuotation?.DocDueDate, "YYYY-MM-DD") as any)
                    : undefined,
                quotation_date: context.selectedQuotation?.DocDate
                    ? (dayjs(context.selectedQuotation?.DocDate, "YYYY-MM-DD") as any)
                    : undefined,
                customer_id: context.selectedQuotation?.customer,
                payment_term: context.selectedQuotation?.payment_terms
                    ? {
                          id: context.selectedQuotation?.payment_terms?.id,
                          value: context.selectedQuotation?.payment_terms?.payment_value,
                      }
                    : undefined,
                freight_term: context.selectedQuotation?.freight_terms
                    ? {
                          id: context.selectedQuotation?.freight_terms?.id,
                          value: context.selectedQuotation?.freight_terms?.freight_value,
                      }
                    : undefined,
                description: context.selectedQuotation?.Description || "",
            });
        }
    }, [props.open]);

    const onSubmit = (data) => {
        console.log(data, 'datadatadatadata');
        
        if (!context.selectedQuotation) return;
        const customer = data.customer_id;

        const formData = {
            Name: data.name || "",
            CardCode: customer?.customer_code,
            CardName: customer?.customer_name,
            customer: customer,
            DocDate: dayjs(data.quotation_date).format("YYYY-MM-DD"),
            DocDueDate: dayjs(data.expiration_date).format("YYYY-MM-DD"),
            Description: data.description,
            NumAtCard: context.project?.projects_name,
            PaymentTerm: data.payment_term.id,
            FreightTerm: data.freight_term.id,
        };

        saveQuote({
            projects_id: id && isProject ? parseInt(id) : undefined,
            ...context.selectedQuotation,
            ...formData,
        }).then((res: any) => {
            const updatedQuotations = context.quotations.map((q) => {
                if (q.DocNum === context.selectedQuotation?.DocNum) {
                    return {
                        ...q,
                        ...formData,
                    } as Quotation;
                }
                return q;
            }) as Quotation[];

            if (!res.error) {
                context.setQuotations(updatedQuotations);
                context.setSelectedQuotation(
                    updatedQuotations.find((q) => q.DocNum === context.selectedQuotation?.DocNum),
                );
                successToast("Quote saved successfully");
                props.onClose();
            } else {
                errorToast("Error saving quote");
            }
        });
    };

    const onDelete = () => {
        if (!context.selectedQuotation) return;
        deleteQuote(context.selectedQuotation.DocNum).then(() => {
            const updatedQuotations = context.quotations.filter((q) => q.DocNum !== context.selectedQuotation?.DocNum);

            if (updatedQuotations.length === 0) {
                navigate(`/dashboard`);
                return;
            }

            context.setQuotations(updatedQuotations);
            context.setSelectedQuotation(updatedQuotations[0]);
            props.onClose();
        });
    };

    const quoteDate = form.watch("quotation_date");

    return (
        <ThemeModal
            header={{
                title: "Edit Quotation",
            }}
            open={props.open}
            onClose={() => props.onClose()}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit}>
                <div className="flex flex-col space-y-4">
                    <Field.Text label="Name" name="name" />
                    <Field.Date label="Quotation Date" name="quotation_date" />
                    <Field.Date
                        label="Expiration Date"
                        name="expiration_date"
                        datePickerProps={{
                            shouldDisableDate: (date) => {
                                return date < dayjs(quoteDate).toDate();
                            },
                        }}
                    />
                    <CustomerSelect />
                    <FrieghtTermsSelect />
                    <PaymentTermsSelect />
                    <Field.Text label="Description" name="description" placeholder="Enter Description" />
                </div>
                <div className="flex space-x-4 mt-10">
                    <Button
                        color="error"
                        variant="outlined"
                        className="!bg-[#FF0F000D]"
                        size="large"
                        loading={deleteLoading}
                        onClick={onDelete}
                        startIcon={<Delete />}
                    >
                        Delete
                    </Button>
                    <Button disabled={deleteLoading} size="large" onClick={() => props.onClose()}>
                        Close
                    </Button>
                    <Button
                        loading={isLoading}
                        disabled={deleteLoading}
                        size="large"
                        color="primary"
                        type="submit"
                        fullWidth
                    >
                        Save
                    </Button>
                </div>
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default EditQuotationModal;
