import { useForm } from "react-hook-form";
import { usePostProjectMutation } from "shared/api";
import { errorToast, successToast } from "shared/utils";
import yup, { yupResolver } from "shared/utils/yup";
import CustomerSelect from "./Selectbox/customer-selectbox";
import FrieghtTermsSelect from "./Selectbox/frieght-terms-selectbox";
import PaymentTermsSelect from "./Selectbox/payment-terms-selectbox";
import { Field } from "./form";
import ThemeFormProvider from "./form/provider";
import Button from "./ui/Button";
import ThemeModal from "./ui/ThemeModal";

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    customer_id: yup.object().typeError("This field is required").required(),
    payment_term: yup.object().typeError("This field is required").required(),
    freight_term: yup.object().typeError("This field is required").required(),
});

const CreateProjectModal = (props: { open: boolean; onClose: () => void }) => {
    const form = useForm({
        resolver: yupResolver(schema),
    });

    const [postProject, { isLoading }] = usePostProjectMutation();

    const onSubmit = (data) => {
        postProject({
            id: undefined,
            name: data.name,
            description: data.description || "",
            customer_id: data.customer_id.customer_code,
            customer_name: data.customer_id.customer_name,
            payment_terms: data.payment_term.value,
            freight_terms: data.freight_term.value,
        })
            .then((res) => {
                successToast("Project created successfully");
                props.onClose();
            })
            .catch((err) => {
                errorToast(err);
            });
    };

    return (
        <ThemeModal
            header={{
                title: "New Project",
            }}
            open={props.open}
            onClose={() => props.onClose()}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit}>
                <div className="flex flex-col space-y-5">
                    <Field.Text label="Name" name="name" placeholder="Enter project name" />
                    <Field.Text label="Description" name="description" placeholder="Enter a description..." />
                    <CustomerSelect />
                    <FrieghtTermsSelect />
                    <PaymentTermsSelect />
                </div>
                <div className="grid grid-cols-12 gap-4 mt-10">
                    <Button size="large" className="col-span-2" onClick={() => props.onClose()}>
                        Close
                    </Button>
                    <Button
                        size="large"
                        loading={isLoading}
                        loadingText="Submitting..."
                        className="col-span-10"
                        color="primary"
                        type="submit"
                    >
                        Create Project
                    </Button>
                </div>
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default CreateProjectModal;
