import { LogoEspen, LogoRevo } from "assets/index";
import { Link } from "react-router-dom";
import UserDropdown from "./UserDropdown";

const Sidebar = () => {
    return (
        <>
            <div className="border-r border-[#D5D5D5] bg-white py-4 px-3 flex flex-col h-full justify-between">
                <div className="flex items-center flex-col space-y-4 mb-8">
                    <a href="https://espenus.com/" target="_blank">
                        <LogoEspen />
                    </a>
                    <Link to={"/dashboard"}>
                        <LogoRevo />
                    </Link>
                </div>
                <UserDropdown />
            </div>
        </>
    );
};

export default Sidebar;
