// Need to use the React-specific entry point to import createApi
import { api } from "./api";

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // authentication
        login: builder.mutation({
            query: (body) => {
                return {
                    url: "auth/login",
                    method: "POST",
                    body,
                };
            },
        }),
        verifyToken: builder.mutation({
            query: () => ({
                url: "auth/verify-token",
                method: "POST",
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useLoginMutation, useVerifyTokenMutation } = authApi;
