import { useController, useFormContext } from "react-hook-form";
import ThemeSelect from "../ui/ThemeSelect";

const SelectField = (props: {
    label?: string;
    name: string;
    placeholder?: string;
    options: { label: string; value: string }[];
}) => {
    const form = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name: props.name,
        control: form.control,
    });

    return (
        <ThemeSelect
            placeholder={props.placeholder}
            options={props.options}
            selectProps={{
                error: form.formState.errors[props.name] ? true : false,
                errorMessages: form.formState.errors[props.name]?.message ?? ("" as any),
                label: props.label,
                fullWidth: true,
                value: value,
                onChange: (e) => onChange(e.target.value),
            }}
        />
    );
};

export default SelectField;
