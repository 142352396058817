import { omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useCategoriesQuery, useGetProductDetailQuery } from "shared/api";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import { errorToast } from "shared/utils";

const useAddProductContent = () => {
    const { productFromPriceList, selectedProduct: priceListProduct } = useQuoteDrawer();
    const [categories, setCategories] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState<any>(priceListProduct);
    const [productFields, setProductFields] = useState<any>();
    const [changedOptions, setChangedOptions] = useState<any[]>([]);
    const { data: categoriesData, isLoading: loading } = useCategoriesQuery(undefined);

    const { data, error, isLoading } = useGetProductDetailQuery(selectedProduct?.products_id, {
        skip: !selectedProduct,
    });

    const [selectedCategory, setSelectedCategory] = useState<{
        parent: any;
        sub_category: any;
    }>(productFromPriceList);

    const onChangeOption = (optionId: any, field: any) => {
        setChangedOptions((prev: any) => {
            const exists = prev.find((op) => op.id === optionId);
            if (exists) {
                return prev.map((op) => {
                    if (op.id === optionId) {
                        return {
                            ...op,
                            field,
                        };
                    }
                    return op;
                });
            }
            return [
                ...prev,
                {
                    id: optionId,
                    field,
                },
            ];
        });
    };

    useEffect(() => {
        if (data) {
            setProductFields(data.fields);
            setSelectedProduct((prev) => ({
                ...prev,
                LineNewCommission: 5,
                fields: data.fields.map((field) => ({
                    ...omit(field, ["options"]),
                    value: field.options.length ? field.options.find((item) => item.default) : {},
                })),
            }));
        }
        error && errorToast("Failed to fetch product details");
    }, [data, error]);

    const categoriesList = () => {
        if (selectedCategory.parent) {
            return selectedCategory.parent.sub_categories;
        }

        return categories || [];
    };

    const onClickItem = (item: any) => {
        if (!selectedCategory.parent)
            setSelectedCategory({
                parent: item,
                sub_category: null,
            });
        else
            setSelectedCategory((prev) => ({
                ...prev,
                sub_category: item,
            }));
    };

    useEffect(() => {
        if (categoriesData) {
            setCategories(categoriesData);
        }
    }, [categoriesData]);

    const selectedProductPrice = useMemo(() => {
        if (!selectedProduct) return 0;
        return selectedProduct?.fields?.reduce((acc: number, field: any) => {
            const changedField = changedOptions.find((op) => op.id === field.id);
            return (
                acc +
                parseFloat(changedField?.field?.base_price || field.value?.base_price || 0) +
                parseFloat(field.LineNewCommission || 0) +
                parseFloat(field.LineOverage || 0)
            );
        }, 0);
    }, [changedOptions, selectedProduct]);

    const selectedProductLineTotal = useMemo(() => {
        if (!selectedProduct) return 0;
        const total = (selectedProductPrice + selectedProduct.LineOverage) * (selectedProduct.quantity || 1);
        return total + (total * selectedProduct.LineNewCommission) / 100;
    }, [selectedProduct]);

    const selectedProductHotPrice = useMemo(() => {
        if (!selectedProduct) return 0;
        return selectedProduct?.fields?.reduce((acc: number, field: any) => {
            const changedField = changedOptions.find((op) => op.id === field.id);
            return acc + parseFloat(changedField?.field?.hot_price || field.value?.hot_price || 0);
        }, 0);
    }, [changedOptions, selectedProduct]);

    const selectedProductNormalPrice = useMemo(() => {
        if (!selectedProduct) return 0;
        return selectedProduct?.fields?.reduce((acc: number, field: any) => {
            const changedField = changedOptions.find((op) => op.id === field.id);
            return acc + parseFloat(changedField?.field?.normal_price || field.value?.normal_price || 0);
        }, 0);
    }, [changedOptions, selectedProduct]);

    const selectedProductBasePrice = useMemo(() => {
        if (!selectedProduct) return 0;
        return selectedProduct?.fields?.reduce((acc: number, field: any) => {
            const changedField = changedOptions.find((op) => op.id === field.id);
            return acc + parseFloat(changedField?.field?.base_price || field.value?.base_price || 0);
        }, 0);
    }, [changedOptions, selectedProduct]);

    return {
        loading,
        categoriesList,
        selectedCategory,
        setSelectedCategory,
        selectedProduct,
        setSelectedProduct,
        productFields,
        isLoading,
        onClickItem,
        changedOptions,
        onChangeOption,
        selectedProductPrice,
        selectedProductLineTotal,
        selectedProductHotPrice,
        selectedProductNormalPrice,
        selectedProductBasePrice,
    };
};

export default useAddProductContent;
