import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "screens/LoginScreen";
import ProjectDetailScreen from "screens/AssetDetailScreen";
import QuoteDrawerWrapper from "shared/components/QuoteDrawerWrapper";
import ValidateToken from "shared/components/layout/ValidateToken";
import { ProjectDetailContextWrapper } from "shared/context/project-detail-context";
import store from "shared/store";
import muiTheme from "shared/utils/muiTheme";
import DashboardScreen from "./screens/Dashboard";
import LayoutWrapper from "./shared/components/layout/Layout";
import NotFound from "screens/NotFound";

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
                <ThemeProvider theme={muiTheme}>
                    <ProjectDetailContextWrapper>
                        <QuoteDrawerWrapper>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<LoginScreen />} />
                                    <Route path="/dashboard" element={<ValidateToken />}>
                                        <Route path="" element={<LayoutWrapper />}>
                                            <Route path="" element={<DashboardScreen />} />
                                            <Route path="detail/:type/:id" element={<ProjectDetailScreen />} />
                                        </Route>
                                    </Route>

                                    {/* Not Found */}
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </BrowserRouter>
                        </QuoteDrawerWrapper>
                    </ProjectDetailContextWrapper>
                    <Toaster position="top-center" />
                </ThemeProvider>
            </Provider>
        </LocalizationProvider>
    );
};

export default App;
