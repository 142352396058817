// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const productApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // products
        products: builder.query({
            query: () => {
                return { url: `products` };
            },
        }),
        productFilters: builder.query({
            query: () => ({ url: "products/filters" }),
        }),
        getProductsByCategory: builder.query({
            query: (id?: string) => ({ url: `products/category${id ? `/${id}` : ""}` }),
        }),
        getProductDetail: builder.query({
            query: (id: string) => ({ url: `product/detail/123/${id}` }),
        }),
        getRevoProducts: builder.query({
            query: () => ({ url: "revo/products" }),
        }),
    }),
});

export const {
    useProductsQuery,
    useGetProductDetailQuery,
    useGetProductsByCategoryQuery,
    useGetRevoProductsQuery,
    useProductFiltersQuery,
} = productApi;
