import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useVerifyTokenMutation } from "shared/api";
import { useAppDispatch } from "shared/store";
import { setUser } from "shared/store/slices";
import { errorToast } from "shared/utils";
import PageLoader from "../ui/PageLoader";

const ValidateToken = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [verifyToken, { isLoading }] = useVerifyTokenMutation(undefined);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        verifyToken(undefined).then((res: any) => {
            if (res.data?.user) {
                dispatch(setUser(res.data.user));
                setIsVerified(true);
            } else {
                localStorage.removeItem("revo_token");
                errorToast("Session expired. Please login again.");
                navigate("/");
            }
        });
    }, []);

    if (isLoading) return <PageLoader />;

    return isVerified ? <Outlet /> : <></>;
};

export default ValidateToken;
