import { createSlice } from "@reduxjs/toolkit";

interface ProjectState {
    selected: any | null;
}

const initialState: ProjectState = {
    selected: null,
};

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProject: (state, action) => {
            state.selected = action.payload;
        },
        removeProject: (state) => {
            state.selected = null;
        },
    },
});

export const { setProject, removeProject } = projectSlice.actions;

export default projectSlice.reducer;
