import React from "react";
import { useParams } from "react-router-dom";
import { AssetTypes } from "shared/utils";

const useAssetType = () => {
    const { type } = useParams<{
        type: AssetTypes;
    }>();

    const isProject = type === AssetTypes.PROJECT;
    const isQuotation = type === AssetTypes.QUOTATION;

    return {
        isProject,
        isQuotation,
    };
};

export default useAssetType;
