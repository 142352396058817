import { Add } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { SearchIcon } from "assets/index";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { DateRange } from "rsuite/esm/DateRangePicker";
import CreateProjectModal from "shared/components/CreateProjectModal";
import StartQuotationModal from "shared/components/StartQuotationModal";
import ThemeFormProvider from "shared/components/form/provider";
import Button from "shared/components/ui/Button";
import { useDebounce } from "use-debounce";
import DashboardDateRangePicker from "./partials/DashboardDateRangePicker";
import ProjectList from "./partials/ProjectList";
import QuotationList from "./partials/QuotationList";
import { startOfMonth, endOfMonth } from "rsuite/esm/utils/dateUtils";

export interface DashboardFilters {
    search: string;
    dateRange: {
        label: string;
        value: DateRange | undefined;
    };
}

const DashboardScreen = () => {
    const [quotaModal, setQuotaModal] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const form = useForm();
    const [filters, setFilters] = useState<DashboardFilters>({
        search: "",
        dateRange: {
            label: "This month",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
    });
    const [filtersSearch] = useDebounce(filters, 500);

    const handleChange = (key: keyof DashboardFilters, value: string) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <>
            <ThemeFormProvider form={form} onSubmit={() => {}}>
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2 grid grid-cols-2 gap-4">
                        <TextField
                            placeholder="Search and filter..."
                            sx={{
                                ".MuiOutlinedInput-notchedOutline": { border: "none" },
                            }}
                            InputProps={{
                                startAdornment: <SearchIcon color="#828282" />,
                            }}
                            value={filters.search || ""}
                            onChange={(e) => {
                                const search = e.target.value;
                                if (e.target.value === "") handleChange("search", "");
                                else handleChange("search", search);
                            }}
                            fullWidth
                        />
                        <DashboardDateRangePicker
                            label={filters.dateRange.label}
                            value={filters.dateRange.value}
                            onChange={(value) => {
                                setFilters((prev) => ({ ...prev, dateRange: value }));
                            }}
                        />
                    </div>

                    <div className="flex items-center justify-end space-x-4">
                        <Button
                            onClick={() => setQuotaModal(true)}
                            color="primary"
                            size="large"
                            variant="outlined"
                            startIcon={<Add />}
                        >
                            Quotation
                        </Button>
                        <Button onClick={() => setProjectModal(true)} color="primary" size="large" startIcon={<Add />}>
                            Project
                        </Button>
                    </div>
                </div>
            </ThemeFormProvider>
            <ProjectList filters={filtersSearch} setQuotaModal={setQuotaModal} setProjectModal={setProjectModal} />
            <QuotationList filters={filtersSearch} />
            <StartQuotationModal open={quotaModal} onClose={() => setQuotaModal(false)} />
            <CreateProjectModal open={projectModal} onClose={() => setProjectModal(false)} />
        </>
    );
};

export default DashboardScreen;
