import { createTheme } from "@mui/material";

const muiTheme = createTheme({
    typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#007EFF",
        },
        error: {
            main: "#FF0F00",
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
                color: "inherit",
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minWidth: "auto",
                    borderRadius: "8px",
                    boxShadow: "none",
                    fontWeight: 600,
                    ":hover": {
                        boxShadow:
                            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                    },
                },
            },
            variants: [
                {
                    props: { color: "inherit", variant: "contained" },
                    style: {
                        backgroundColor: "#E9E9E9",
                        color: "#828282",
                        ":hover": {
                            backgroundColor: "#E9E9E9",
                        },
                    },
                },
                {
                    props: { color: "primary", variant: "outlined" },
                    style: {
                        color: "#007EFF",
                        backgroundColor: "#E5F2FF",
                        ":hover": {
                            backgroundColor: "#E5F2FF",
                        },
                    },
                },
                {
                    props: { color: "error", variant: "outlined" },
                    style: {
                        ":hover": {
                            backgroundColor: "#FF0F00 !important",
                            color: "#fff",
                        },
                    },
                },
            ],
        },
        MuiIconButton: {
            defaultProps: {
                classes: {
                    root: "!rounded-md !bg-[#E9E9E9]",
                },
            },
        },
        MuiFormControl: {
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        ".MuiFormLabel-root": {
                            top: "-3px",
                        },
                    },
                },
            ],
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
                classes: {
                    root: "shadow-none !border-none bg-white",
                    select: "text-sm !text-gray-500 !bg-white",
                },
            },
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        ".MuiSelect-select": {
                            paddingLeft: "0",
                            paddingTop: "12.5px",
                            paddingBottom: "12.5px",
                        },
                    },
                },
            ],
        },
        MuiTextField: {
            defaultProps: {
                autoComplete: "off",
                fullWidth: true,
                variant: "outlined",
                classes: {
                    root: "shadow-none !border-none bg-white !rounded-[8px] h-12",
                },
            },
            variants: [
                {
                    props: { variant: "outlined" },
                    style: {
                        ".MuiInputBase-input": {
                            paddingLeft: "0",
                            paddingTop: "12.5px",
                            paddingBottom: "12.5px",
                        },
                        ".MuiFormLabel-root": {
                            top: "-3px",
                        },
                    },
                },
            ],
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    paddingLeft: "16px",

                    "&:not(.MuiInputBase-multiline)": {
                        borderRadius: "8px",
                        height: "48px",
                    },

                    "&::placeholder": {
                        color: "#828282",
                    },

                    ".MuiInputBase-inputAdornedStart": {
                        paddingLeft: "12px",
                    },

                    ":before": {
                        display: "none",
                    },
                    ":after": {
                        display: "none",
                    },
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                classes: {
                    root: "text-[18px] !text-gray-500 !px-4 !py-2",
                },
            },
            variants: [
                {
                    props: { disabled: true, selected: true },
                    style: {
                        backgroundColor: "#007EFF",
                        color: "#fff",
                    },
                },
            ],
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    paddingTop: 5,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {},
            },
        },
    },
});

export default muiTheme;
