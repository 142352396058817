import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { errorToast } from "./toast";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("revo_token");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response && response.data ? response.data : response;
    },
    (error) => {
        if (error.response?.status === 401) {
            errorToast("Session expired. Please login again.");
            localStorage.removeItem("revo_token");
            window.location.href = "/";
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: "" },
    ): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig["method"];
            body?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
            headers?: AxiosRequestConfig["headers"];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, body, params, headers }) => {
        try {
            const result = await axiosInstance({
                url: baseUrl + url,
                method,
                data: body,
                params,
                headers,
            });

            return { data: result };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
