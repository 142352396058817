import { createContext, useContext, useState } from "react";
import { Quotation } from "shared/types/quotation";

export interface ProjectDetailContext {
    quotations: Quotation[];
    setQuotations: React.Dispatch<React.SetStateAction<Quotation[]>>;
    selectedQuotation: Quotation | undefined;
    setSelectedQuotation: React.Dispatch<React.SetStateAction<Quotation | undefined>>;
    project?: any;
    setProject: React.Dispatch<React.SetStateAction<any>>;
}

const Context = createContext<ProjectDetailContext>({
    quotations: [],
    setQuotations: () => {},
    selectedQuotation: undefined,
    setSelectedQuotation: () => {},
    project: null,
    setProject: () => {},
});

export const ProjectDetailContextWrapper = (props: { children: React.ReactNode }) => {
    const [quotations, setQuotations] = useState<Quotation[]>([]);
    const [selectedQuotation, setSelectedQuotation] = useState<Quotation>();
    const [project, setProject] = useState<any>();

    return (
        <Context.Provider
            value={{
                quotations,
                setQuotations,
                selectedQuotation,
                setSelectedQuotation,
                project,
                setProject,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export const useProjectDetailContext = () => {
    return useContext(Context);
};
