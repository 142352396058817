import {
    ClickAwayListener,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { CircleCrossIcon, SearchIcon } from "assets/index";
import { useEffect, useState } from "react";
import axiosInstance from "shared/utils/axios";
import { twMerge } from "tailwind-merge";
import { useDebounce } from "use-debounce";

const SearchInput = (props: {
    onChange: (value: string) => void;
    onSelectProduct: (product: any) => void;
    hasMatchFound?: (loaded: boolean) => void;
    onClear?: () => void;
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState<any[]>([]);
    const [debouncedSearchValue] = useDebounce(searchValue, 500);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [open, setOpen] = useState(false);

    const getCrossReferences = async () => {
        try {
            const data = (await axiosInstance.post("/cross-reference", {
                search: debouncedSearchValue,
            })) as any[];
            setOptions(data);
            setOpen(true);
            props.hasMatchFound && props.hasMatchFound(data.length > 0);
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        props.onChange(selectedProduct ? selectedProduct.products_model : searchValue);
    }, [selectedProduct, searchValue]);

    useEffect(() => {
        if (!debouncedSearchValue || selectedProduct) return;
        getCrossReferences();
    }, [debouncedSearchValue]);

    return (
        <div className="w-full relative">
            <TextField
                fullWidth
                placeholder="Search products by manufacturer, catalog number, or description..."
                value={selectedProduct ? selectedProduct.products_model : searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                    setSelectedProduct(null);
                }}
                InputProps={{
                    classes: {
                        root: "!pr-2",
                    },
                    startAdornment: <SearchIcon color="#828282" />,
                    endAdornment: (
                        <IconButton
                            className="!bg-transparent !p-0"
                            onClick={() => {
                                setSearchValue("");
                                setSelectedProduct(null);
                                setOptions([]);
                                props.onClear && props.onClear();
                            }}
                        >
                            <CircleCrossIcon color="#828282" />
                        </IconButton>
                    ),
                }}
            />
            <ClickAwayListener
                onClickAway={() => {
                    setOpen(false);
                }}
            >
                <div
                    className={twMerge(
                        "absolute top-[60px] w-full bg-white rounded-md z-10 hidden",
                        open && options.length > 0 ? "block" : "hidden",
                    )}
                >
                    <TableContainer sx={{ maxHeight: 440 }} className="bg-white !rounded-md !shadow-lg">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="!font-bold">Manufacturer</TableCell>
                                    <TableCell className="!font-bold">Catalog Number</TableCell>
                                    <TableCell className="!font-bold">Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {options?.map((option) => (
                                    <TableRow
                                        onClick={() => {
                                            setSelectedProduct(option);
                                            props.onSelectProduct(option);
                                            setOpen(false);
                                        }}
                                        className="cursor-pointer hover:bg-gray-100"
                                        key={option.products_id}
                                    >
                                        <TableCell>{option.manufacturer?.manufacturers_name ?? "-"}</TableCell>
                                        <TableCell>{option.products_model}</TableCell>
                                        <TableCell className="break-words whitespace-normal">
                                            {option.description?.products_description ?? "-"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default SearchInput;
