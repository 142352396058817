import { createSlice } from "@reduxjs/toolkit";

interface UserState {
    info: {
        id: number;
        username: string;
        authority: string;
    } | null;
}

const initialState: UserState = {
    info: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.info = action.payload;
        },
        removeUser: (state) => {
            state.info = null;
        },
    },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
