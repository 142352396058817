import { Quotation } from "shared/types/quotation";

export const DefaultQuotation = (): Quotation => {
    // six digit random number
    const randomNum = Math.floor(100000 + Math.random() * 900000);

    return {
        DocNum: randomNum,
        DocDate: "",
        DocDueDate: "",
        Name: "",
        Description: "",
        CardCode: "",
        CardName: "",
        ContactName: "",
        ContactEmail: "",
        NumAtCard: "",
        FreightTerm: "",
        PaymentTerm: "",
        RequestNum: 1,
        Version: 1,
        customer: {
            customer_id: "",
            customer_code: "",
            customer_name: "",
            customer_phone: "",
            customer_fax: "",
            customer_email: "",
            customer_address: "",
            customer_city: "",
            customer_zip: "",
            customer_country: "",
        },
        detail: [],
        newQuote: true,
    };
};
