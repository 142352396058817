import "react-perfect-scrollbar/dist/css/styles.css";
import { Outlet } from "react-router-dom";
import ThemePerfectScrollbar from "../ui/ThemePerfectScrollbar";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = () => {
    return (
        <div className="flex h-screen">
            <Sidebar />
            <ThemePerfectScrollbar className="w-full h-full bg-[#F4F4F4]">
                <div className="flex-1 py-[17px] px-[23px] flex flex-col">
                    <Header />
                    <Outlet />
                </div>
            </ThemePerfectScrollbar>
        </div>
    );
};

export default Layout;
