import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { UpRightArrowIcon } from "assets/index";
import { useEffect, useMemo, useState } from "react";
import { useCategoriesQuery, useGetProductsByCategoryQuery } from "shared/api";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import Button from "shared/components/ui/Button";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import { QuoteDrawerTabs, errorToast, successToast } from "shared/utils";

const PriceListItem = (props: { product: any; selectedColumns: string[]; canAddProduct: boolean }) => {
    const quoteDetail = useProjectDetailContext();
    const { setDrawerOpen, handleTabChange, setProductFromPriceList, setSelectedProduct } = useQuoteDrawer();
    const [catId, setCatId] = useState<string>(""); // [catId, setCatId] = useState<string>("");
    const [callCatApi, setCallCatApi] = useState(false);
    const { data, isLoading } = useCategoriesQuery(undefined, {
        skip: !callCatApi,
    });
    const { data: productData, isLoading: productLoading } = useGetProductsByCategoryQuery(catId, {
        skip: !catId,
    });

    const loading = isLoading || productLoading;

    const product = props.product;
    // sort by name
    let fields = [...product?.fields]
        .sort((a, b) => a.order - b.order)
        ?.filter((field: any) => props.selectedColumns.includes(field.name));

    const defaultOptions = fields
        ?.map((field: any) => {
            return field.options.find((f: any) => f.default);
        })
        .filter((field: any) => field);

    // price
    const price = defaultOptions.reduce((acc: number, field: any) => {
        return acc + parseFloat(field.base_price || 0);
    }, 0);

    const defaultOpt = useMemo(() => {
        return product?.fields.filter((f: any) => f.options.length).map((f) => f.options.find((o: any) => o.default));
    }, [product]);

    const description =
        useMemo(
            () =>
                defaultOpt
                    ?.map((f) => f?.description)
                    .filter((f) => f)
                    .join(","),
            [defaultOpt],
        ) || "";

    useEffect(() => {
        if (data) {
            const parent = product.parent_categories.find((f: any) => f.is_parent).id;
            const sub_category = product.parent_categories.filter((f: any) => !f.is_parent)?.[0].id;
            const dataParent = data.find((f: any) => f.categories_id === parent);
            const dataSub = dataParent.sub_categories.find((f: any) => f.categories_id === sub_category);
            if (parent && sub_category) {
                setProductFromPriceList({
                    parent: dataParent,
                    sub_category: dataSub,
                });
                setCatId(dataSub.categories_id);
            }
        }
    }, [data]);

    useEffect(() => {
        if (productData) {
            const productExists = productData.find((f) => f.products_id === props.product.id);
            if (productExists) {
                setSelectedProduct({
                    ...productExists,
                    quantity: 1,
                });
                setDrawerOpen((prev) => ({
                    ...prev,
                    type: "add",
                }));
                handleTabChange(QuoteDrawerTabs.ADD_ITEM);
            } else {
                errorToast("Product not found");
            }
        }
    }, [productData]);

    const onApply = async () => {
        setCallCatApi(true);
    };

    const onApply2 = () => {
        const options = fields.map((field: any) => {
            return field.options.find((f: any) => f.default);
        });

        const catalogNumber = defaultOpt.map((f) => f.name).join("/");

        const fieldTotal =
            options?.reduce((acc: number, field: any) => {
                return acc + parseFloat(field?.base_price || 0);
            }, 0) || 0;

        const lineTotal = fieldTotal * 1;

        if (quoteDetail.selectedQuotation) {
            quoteDetail.setSelectedQuotation({
                ...quoteDetail.selectedQuotation,
                detail: [
                    ...quoteDetail.selectedQuotation.detail,
                    {
                        LineNum: (quoteDetail.selectedQuotation?.detail?.length || 0) + 1,
                        GenericModel: catalogNumber,
                        Description: description,
                        Quantity: 1,
                        Price: fieldTotal,
                        LineNewCommission: product?.LineNewCommission || 0,
                        LineOverage: product?.LineOverage || 0,
                        LineTotal: lineTotal,
                    },
                ],
            });
        }

        successToast("Product added to quote");
    };

    return (
        <div className="bg-white shadow-[0px_1px_4px_0px_#00000029] rounded-md overflow-hidden">
            <div className="flex items-center space-x-6 py-4 px-6 relative w-full">
                <div className="w-[235px] h-[120px] bg-[#080808] rounded-md">
                    <img
                        src={`https://www.espenus.com/images/${product.image}`}
                        className="h-[120px] w-full object-contain rounded-md"
                        alt=""
                    />
                </div>
                <h4 className="text-black text-[18px] leading-6 font-bold">{product.name}</h4>
                {props.canAddProduct && (
                    <Button
                        className="!absolute top-1/2 -translate-y-1/2 !right-4 !text-primary !bg-[#007EFF] !bg-opacity-10"
                        size="large"
                        color="primary"
                        onClick={onApply}
                        loading={loading}
                        startIcon={<UpRightArrowIcon />}
                    >
                        Add To Quote
                    </Button>
                )}
            </div>
            <TableContainer>
                <CustomTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell className="!whitespace-normal">Description</TableCell>
                            {fields.map((field: any, index: number) => (
                                <TableCell key={index}>{field.name}</TableCell>
                            ))}
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{product.model}</TableCell>
                            <TableCell className="!whitespace-normal" width="40%">
                                {description || "-"}
                            </TableCell>
                            {fields.map((field: any, index: number) => {
                                const defaultField = field?.options?.find((f) => f.default)?.description || "-";
                                return <TableCell key={index}>{defaultField}</TableCell>;
                            })}
                            <TableCell>${isNaN(price) ? 0 : price}</TableCell>
                        </TableRow>
                        <TableRow className="!bg-[#F5F6F7]">
                            <TableCell>
                                <b>OPTIONS</b>
                            </TableCell>
                            <TableCell width="40%"></TableCell>
                            {fields.map((field: any, index: number) => {
                                return <TableCell key={index}></TableCell>;
                            })}
                            <TableCell></TableCell>
                        </TableRow>
                        {
                            // create a row for each option in field variable
                            fields.map((field: any, index: number) => {
                                return field.options
                                    .filter((option: any) => !option.default)
                                    .map((option: any, index: number) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{option.name}</TableCell>
                                                <TableCell width="40%">{option.description}</TableCell>
                                                {fields
                                                    ?.filter((f: any) => props.selectedColumns.includes(f.name))
                                                    .map((f: any, index: number) => {
                                                        return (
                                                            <TableCell key={index}>
                                                                {f.options.find((o: any) => o.id === option.id)
                                                                    ?.description || "-"}
                                                            </TableCell>
                                                        );
                                                    })}
                                                <TableCell>${option.base_price}</TableCell>
                                            </TableRow>
                                        );
                                    });
                            })
                        }
                    </TableBody>
                </CustomTable>
            </TableContainer>
        </div>
    );
};

export default PriceListItem;

const CustomTable = styled(Table)(({ theme }) => ({
    marginBottom: "20px",

    ".MuiTableCell-root": {
        border: "1px solid #D5D5D5",
        fontSize: "16px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#333333",
        padding: "3px 15px",
        whiteSpace: "nowrap",
    },
    ".MuiTableHead-root": {
        ".MuiTableCell-root": {
            backgroundColor: "#616162",
            color: "#fff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
        },
    },
}));
