import { Button, Table, TableBody, TableCell, TableHead, TableRow, styled } from "@mui/material";
import { CheckIcon, LinkOpenIcon } from "assets/index";
import { useEffect } from "react";
import { useCrossReferenceDetailMutation } from "shared/api";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import QuoteCard from "../../QuoteCard";

const MatchFound = (props: { product: any }) => {
    const product = props.product;
    const [getDetail, { data }] = useCrossReferenceDetailMutation();
    const drawer = useQuoteDrawer();
    const quoteDetail = useProjectDetailContext();

    useEffect(() => {
        if (!product.manufacturer?.manufacturers_id) return;
        getDetail({
            id: product.manufacturer?.manufacturers_id,
            model: product.products_model,
        });
    }, [product]);

    const record = data?.revo?.fields;

    const fieldTotal = record?.reduce((acc, field) => {
        return acc + parseFloat(field?.options.find((op) => op.default)?.normal_price || 0);
    }, 0);

    const options = data?.vendor?.fields?.map((field) => {
        return field.options_id;
    });

    const catalogNumber = record
        ?.map((f) => {
            const value = f.options.find((op) => options.includes(op.id));
            return value?.name || value?.description;
        })
        .filter((f) => f)
        .join("/");

    const description = record
        ?.map((f) => {
            const value = f.options.find((op) => options.includes(op.id));
            return value?.name || value?.description;
        })
        .filter((f) => f)
        .join(",");

    const onAdd = () => {
        if (!quoteDetail.selectedQuotation) return;

        const lineTotal = fieldTotal * 1;

        quoteDetail.setSelectedQuotation({
            ...quoteDetail.selectedQuotation,
            detail: [
                ...quoteDetail.selectedQuotation.detail,
                {
                    LineNum: (quoteDetail.selectedQuotation?.detail?.length || 0) + 1,
                    GenericModel: catalogNumber,
                    Description: description,
                    Quantity: 1,
                    Price: fieldTotal,
                    LineNewCommission: 0,
                    LineOverage: 0,
                    LineTotal: lineTotal,
                },
            ],
        });

        drawer.setIsQuoteChanged(true);

        drawer.setDrawerOpen((prev) => ({
            ...prev,
            open: false,
        }));
    };

    return (
        <QuoteCard
            childrenClassName="!p-0 !pb-6"
            title={
                <>
                    <span className="text-black opacity-50">Best Match Found For:</span> {product.products_model}
                </>
            }
        >
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <TableCell width="50%%">{product.manufacturer?.manufacturers_name}</TableCell>
                        <TableCell width={"200px"}>Manufacturer</TableCell>
                        <TableCell width="50%%">REVO Lighting</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{data?.vendor?.description?.products_name || "-"}</TableCell>
                        <TableCell>Part Number</TableCell>
                        <TableCell>{catalogNumber || "-"}</TableCell>
                    </TableRow>
                    {data?.revo?.fields.length ? (
                        data?.revo?.fields
                            ?.filter((item) => item.options.length)
                            .map((item, index) => {
                                const revoDetail = item.options.find((op) => options.includes(op.id));
                                const vendorDetail = data?.vendor?.fields?.find((f) => f.options_id === revoDetail?.id);

                                return (
                                    <TableRow>
                                        <TableCell>
                                            {item.name === "Fixture Series"
                                                ? data?.vendor?.products_model
                                                : vendorDetail?.cp_option_value || "-"}
                                        </TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{revoDetail?.name || revoDetail?.description || "-"}</TableCell>
                                    </TableRow>
                                );
                            })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3}>No data found</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </CustomTable>
            {data?.vendor && (
                <div className="flex items-center justify-center space-x-7 mt-5">
                    {data?.vendor?.revo_xref_spec_sheet && (
                        <Button
                            size="large"
                            startIcon={<LinkOpenIcon />}
                            onClick={() => {
                                window.open(data.vendor?.revo_xref_spec_sheet.url, "_blank");
                            }}
                        >
                            View Spec Sheet
                        </Button>
                    )}
                    {data?.revo?.fields.length > 0 && (
                        <>
                            <Button onClick={onAdd} size="large" startIcon={<CheckIcon />} color="primary">
                                Add REVO {product.products_model}
                            </Button>
                            {data?.vendor?.revo_xref_spec_sheet && (
                                <Button
                                    size="large"
                                    startIcon={<LinkOpenIcon />}
                                    onClick={() => {
                                        window.open(data.vendor?.revo_xref_spec_sheet.url, "_blank");
                                    }}
                                >
                                    View Spec Sheet
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </QuoteCard>
    );
};

export default MatchFound;

const CustomTable = styled(Table)(({ theme }) => ({
    "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
            borderBottom: "1px solid #616162",
            color: "#25282B",
            fontSize: "18px",
            lineHeight: "18px",
            fontWeight: 600,
        },
    },
    "& .MuiTableCell-root": {
        textAlign: "center",
        padding: "27px 16px",

        "&:nth-child(2)": {
            backgroundColor: "#F5F6F7",
            color: "#616162",
            fontWeight: 600,
            borderLeft: "1px solid #D5D5D5",
            borderRight: "1px solid #D5D5D5",
        },
    },
}));
