// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const manuFacturerapi = api.injectEndpoints({
    endpoints: (builder) => ({
        // manufacturers
        manufacturers: builder.query({
            query: () => ({ url: "manufacturers" }),
        }),
    }),
});

export const { useManufacturersQuery } = manuFacturerapi;
