import { ButtonProps, CircularProgress, Button as CustomButton } from "@mui/material";

const Button = (
    props: ButtonProps & {
        loading?: boolean;
        loadingText?: string;
    },
) => {
    return (
        <CustomButton
            {...props}
            disabled={props.disabled || props.loading}
            style={{ position: "relative" }}
            startIcon={props.loading ? <></> : props.startIcon}
        >
            {props.loading ? (
                <span className="flex items-center space-x-3">
                    <CircularProgress size={20} />
                    <span>{props.loadingText ? props.loadingText : "Loading..."}</span>
                </span>
            ) : (
                props.children
            )}
        </CustomButton>
    );
};

export default Button;
