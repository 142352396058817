import { Button } from "@mui/material";
import QuoteCard from "../../QuoteCard";
import { PlusIcon } from "assets/index";

const NoMatchFound = (props: { value: string }) => {
    return (
        <QuoteCard
            labelClassName="text-center text-[#25282B] text-[18px] leading-5 w-full"
            title={
                <>
                    <span className="text-black opacity-50">No Match Found For:</span> {props.value}
                </>
            }
        >
            <div className="p-10 flex items-center justify-center flex-col space-y-8">
                <p>Try another search or add a new item:</p>
                <Button
                    size="large"
                    className="!bg-primary-light !text-primary"
                    color="primary"
                    startIcon={<PlusIcon />}
                >
                    Add New Item
                </Button>
            </div>
        </QuoteCard>
    );
};

export default NoMatchFound;
