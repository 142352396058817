// Need to use the React-specific entry point to import createApi
import { api } from "./api";

// Define a service using a base URL and expected endpoints
export const soapApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // soap customers
        customers: builder.query({
            query: () => ({ url: "soap/customers" }),
        }),
        paymentTerms: builder.query({
            query: () => ({ url: "soap/payment-terms" }),
        }),
        freightTerms: builder.query({
            query: () => ({ url: "soap/freight-terms" }),
        }),
    }),
});

export const { useCustomersQuery, usePaymentTermsQuery, useFreightTermsQuery } = soapApi;
