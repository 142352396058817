import { Delete } from "@mui/icons-material";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { useState } from "react";
import { useQuoteDrawer } from "shared/components/QuoteDrawerWrapper";
import Button from "shared/components/ui/Button";
import ThemeCheckbox from "shared/components/ui/ThemeCheckbox";
import { useProjectDetailContext } from "shared/context/project-detail-context";
import { Quotation } from "shared/types/quotation";

const QuoteTable = () => {
    const drawer = useQuoteDrawer();
    const context = useProjectDetailContext();
    const quotation = context.selectedQuotation as Quotation;
    const [selected, setSelected] = useState<number[]>([]);

    const onDelete = () => {
        context.setSelectedQuotation((prev) =>
            prev
                ? {
                      ...prev,
                      detail: prev.detail?.filter((doc) => !selected.includes(doc.LineNum || 0)),
                  }
                : prev,
        );
        setSelected([]);
        drawer.setIsQuoteChanged(true);
    };

    const totalCommission = quotation?.detail
        ?.reduce((acc: number, doc: any) => {
            return acc + ((doc.LineTotal || 0) - (doc.LineTotal || 0) / (1 + (doc.LineNewCommission || 0) / 100));
        }, 0)
        .toFixed(2);

    const totalOverage = quotation?.detail
        ?.reduce((acc: number, doc: any) => {
            return acc + doc.LineOverage;
        }, 0)
        .toFixed(2);

    const totalLineTotal = quotation?.detail
        ?.reduce((acc: number, doc: any) => {
            return acc + doc.LineTotal;
        }, 0)
        .toFixed(2);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            const newSelecteds = quotation?.detail?.map((n) => n.LineNum || 0) || [];
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (lineNum: number) => {
        const selectedIndex = selected.indexOf(lineNum);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, lineNum);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const onChangeField = (field: keyof Quotation["detail"][0], value: any, lineNum: number) => {
        if (!context.selectedQuotation) return;

        context.setSelectedQuotation({
            ...context.selectedQuotation,
            detail: context.selectedQuotation.detail?.map((doc, index) => {
                if (doc.LineNum === lineNum) {
                    return {
                        ...doc,
                        [field]: value,
                    };
                }

                return doc;
            }),
        });

        drawer.setIsQuoteChanged(true);
    };

    return (
        <Box sx={{ position: "relative" }}>
            {selected.length ? <SelectedToolbar numSelected={selected} onDelete={onDelete} /> : <></>}
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <TableCell width={"5%"} align="center" className="!text-center">
                            <ThemeCheckbox
                                disabled={!quotation?.detail?.length}
                                checked={
                                    quotation?.detail.length ? selected.length === quotation?.detail?.length : false
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Catalog Number</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Comments</TableCell>
                        <TableCell>Stock</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell className="!text-right">Unit Price</TableCell>
                        <TableCell className="!text-right">Commission</TableCell>
                        <TableCell className="!text-right">Overage</TableCell>
                        <TableCell className="!text-right">Line Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quotation?.detail?.map((doc, index) => {
                        const comission = (
                            (doc.LineTotal || 0) -
                            (doc.LineTotal || 0) / (1 + (doc.LineNewCommission || 0) / 100)
                        ).toFixed(2);

                        return (
                            <TableRow key={index}>
                                <TableCell width={"5%"} align="center">
                                    <ThemeCheckbox
                                        checked={selected.indexOf(doc.LineNum || 0) !== -1}
                                        onChange={(event) => handleClick(doc.LineNum || 0)}
                                    />
                                </TableCell>
                                <TableCell width={"10%"}>
                                    <input
                                        type="text"
                                        placeholder="Type"
                                        className="h-10 rounded-md px-4 w-full"
                                        value={doc.LineType}
                                        onChange={(e) => {
                                            onChangeField("LineType", e.target.value, doc.LineNum || 0);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{doc.GenericModel}</TableCell>
                                <TableCell>{doc.Description || "-"}</TableCell>
                                <TableCell width={"15%"}>
                                    <input
                                        type="text"
                                        placeholder="Comments"
                                        className="h-10 rounded-md px-4 w-full"
                                        value={doc.LineNotes}
                                        onChange={(e) => {
                                            onChangeField("LineNotes", e.target.value, doc.LineNum || 0);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>----</TableCell>
                                <TableCell>{doc.Quantity} pcs</TableCell>
                                <TableCell align="right">${doc.Price}</TableCell>
                                <TableCell align="right">${comission}</TableCell>
                                <TableCell align="right">${doc.LineOverage}</TableCell>
                                <TableCell align="right">${doc.LineTotal}</TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell className="!border-r-0" colSpan={7}></TableCell>
                        <TableCell className="!border-l-0" align="right">
                            <b>TOTAL</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>${totalCommission}</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>${totalOverage}</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>${totalLineTotal}</b>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </CustomTable>
        </Box>
    );
};

export default QuoteTable;

interface EnhancedTableToolbarProps {
    numSelected: number[];
    onDelete: () => void;
}

function SelectedToolbar(props: EnhancedTableToolbarProps) {
    return (
        <Toolbar
            className="bg-primary-light"
            sx={{
                pl: { sm: 2 },
                pr: { xs: 2, sm: 2 },
            }}
        >
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                {props.numSelected.length} selected
            </Typography>
            <Tooltip title="Delete">
                <Button
                    onClick={props.onDelete}
                    variant="outlined"
                    size="large"
                    color="error"
                    className="!bg-[#FF0F000D] !min-w-0 !h-10"
                    startIcon={<Delete />}
                >
                    Delete
                </Button>
            </Tooltip>
        </Toolbar>
    );
}

const CustomTable = styled(Table)(({ theme }) => ({
    borderLeft: "none",
    borderRight: "none",
    width: "100%",
    fontSize: "16px",
    lineHeight: "18px",

    "& th": {
        backgroundColor: theme.palette.background.default,
        border: `1px solid #D5D5D5`,
        padding: "8px 10px",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: "18px",

        ":first-child": {
            borderLeft: "none",
        },

        ":last-child": {
            borderRight: "none",
        },
    },

    "& td": {
        border: `1px solid #D5D5D5`,
        padding: "20px 10px",

        ":first-child": {
            borderLeft: "none",
        },

        ":last-child": {
            borderRight: "none",
        },
    },

    "& tr": {
        cursor: "pointer",

        "&:nth-of-type(odd)": {
            backgroundColor: "#F5F6F7",
        },

        "&:hover": {
            backgroundColor: "#E5F2FF",
        },
    },
}));
