import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

const ThemeSelect = (props: {
    placeholder?: string;
    options: {
        label: string;
        value: string;
    }[];
    selectProps?: SelectProps & {
        errorMessages?: string;
    };
}) => {
    return (
        <FormControl fullWidth error={props.selectProps?.error}>
            <InputLabel id="demo-simple-select-helper-label">{props.selectProps?.label}</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={props.selectProps?.label}
                fullWidth
                variant="outlined"
                {...props.selectProps}
            >
                {props.options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {props.selectProps?.error && <FormHelperText error>{props.selectProps.errorMessages}</FormHelperText>}
        </FormControl>
    );
};

export default ThemeSelect;
