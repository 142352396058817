import { Skeleton } from "@mui/material";

const PriceListLoading = () => {
    return (
        <div className="flex flex-col space-y-6">
            {[...Array(5)].map((_, index) => (
                <PriceListLoadingSkeleton key={index} />
            ))}
        </div>
    );
};

export default PriceListLoading;

export const PriceListLoadingSkeleton = () => {
    return (
        <div className="bg-white shadow-[0px_1px_4px_0px_#00000029] rounded-md overflow-hidden">
            <div className="flex items-center space-x-6 py-4 px-6 relative w-full">
                <div className="w-[235px] h-[120px] rounded-md overflow-hidden">
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
                <h4 className="text-black text-[18px] leading-6 font-bold">
                    <Skeleton variant="text" width="200px" height="40px" />
                </h4>
            </div>
            <div className="px-6 pb-4">
                {[...Array(3)].map((_, index) => (
                    <Skeleton variant="text" width="100%" height="40px" />
                ))}
            </div>
        </div>
    );
};
