import { FormControl, FormHelperText, TextField } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

const CustomTextField = (props: { label?: string; name: string; placeholder?: string }) => {
    const form = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name: props.name,
        control: form.control,
    });

    const error = form.formState.errors[props.name];

    return (
        <FormControl fullWidth error={error ? true : false}>
            <TextField
                variant="outlined"
                fullWidth
                label={props.label}
                value={value}
                error={error ? true : false}
                onChange={(e) => onChange(e.target.value)}
                placeholder={props.placeholder}
            />
            {error && <FormHelperText error>{(error as any).message}</FormHelperText>}
        </FormControl>
    );
};

export default CustomTextField;
