import { Checkbox, CheckboxProps, styled } from "@mui/material";

const ThemeCheckbox = (props: CheckboxProps) => {
    return <CustomCheckbox {...props} />;
};

export default ThemeCheckbox;

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: "#D5D5D5",
}));
